import { INotificationFilters } from "../../interfaces/NotificationFilters.interface";
import axios from "axios";

export const GetCallList = (
  page: number = 1,
  pageSize: number = 15,
  searchTerm: string = ""
) => {
  if (searchTerm) {
    return axios
      .get(`/api/lead/calls/?search=${encodeURI(searchTerm)}`)
      .then((r) => r.data);
  } else {
    return axios
      .get(`/api/lead/calls/?page=${page}&page_size=${pageSize}`)
      .then((r) => r.data);
  }
};

export const getCallbackLeads = (
  page: number = 1,
  pageSize: number = 15,
  searchTerm: string = ""
) => {
  if (searchTerm) {
    return axios
      .get(`/api/lead/callback/?search=${encodeURI(searchTerm)}`)
      .then((r) => r.data);
  } else {
    return axios
      .get(`/api/lead/callback/?page=${page}&page_size=${pageSize}`)
      .then((r) => r.data);
  }
};

export const getSingleMarketplaceLead = (id: string) => {
  return axios.get(`/api/marketplace/${id}/`).then((r) => r.data);
};

export const getMarketplaceLeadDetails = (id: string) => {
  return axios.get(`/api/marketplace/${id}/lead_details/`).then((r) => r.data);
};

export const getMarketplaceLeads = (
  page: number = 1,
  pageSize: number = 15,
  filters: INotificationFilters | null = null,
  sortColumn: string | null = null,
  sortOrder: "asc" | "desc" | null = null
) => {
  let url = "/api/marketplace/";

  if (filters) {
    url =
      url +
      "?" +
      new URLSearchParams({
        ...filters,
        page: page.toString(),
        page_size: pageSize.toString(),
      } as any).toString();
  } else {
    url =
      url +
      "?" +
      new URLSearchParams({
        page: page.toString(),
        page_size: pageSize.toString(),
      } as any).toString();
  }

  if (sortColumn) {
    url = url + "&ordering=" + (sortOrder === "desc" ? "-" : "") + sortColumn;
  }

  return axios.get(url).then((r) => r.data);
};

export const getQualifiedLeads = (
  page: number = 1,
  pageSize: number = 15,
  searchTerm: string = ""
) => {
  if (searchTerm) {
    return axios
      .get(`/api/lead/qualified/?search=${encodeURI(searchTerm)}`)
      .then((r) => r.data);
  } else {
    return axios
      .get(`/api/lead/qualified/?page=${page}&page_size=${pageSize}`)
      .then((r) => r.data);
  }
};

export const getSoldLeads = (
  page: number = 1,
  pageSize: number = 15,
  searchTerm: string = ""
) => {
  if (searchTerm) {
    return axios
      .get(`/api/lead/sold/?search=${encodeURI(searchTerm)}`)
      .then((r) => r.data);
  } else {
    return axios
      .get(`/api/lead/sold/?page=${page}&page_size=${pageSize}`)
      .then((r) => r.data);
  }
};

export const getRemovedLeads = (
  page: number = 1,
  pageSize: number = 15,
  searchTerm: string = ""
) => {
  if (searchTerm) {
    return axios
      .get(`/api/lead/removed/?search=${encodeURI(searchTerm)}`)
      .then((r) => r.data);
  } else {
    return axios
      .get(`/api/lead/removed/?page=${page}&page_size=${pageSize}`)
      .then((r) => r.data);
  }
};

export const getLeadsStats = (startDate: string, endDate: string) => {
  return axios
    .get(`/callcenter/leads/kpi/${startDate}/${endDate}`)
    .then((r) => r.data);
};

export type LeadEngagement = {
  type: "call_disposition" | "update";
  action:
    | "no_answer"
    | "not_interested"
    | "qualified"
    | "callback_requested"
    | "update_lead";
  log: string;
  lead: string;
};

export type LeadPurchase = {
  lead: string;
  callcenter?: boolean;
};

export const logLeadEngagement = (data: LeadEngagement) => {
  return axios.post("/api/lead/logging/engagement/", data).then((r) => r.data);
};

export const purchaseLead = (data: LeadPurchase) => {
  return axios.post("/api/buy/lead/", data).then((r) => r.data);
};

export const getLeadLogsByLead = (lead: string) => {
  return axios
    .get(`/api/lead/logging/engagement/?lead__id=${lead}`)
    .then((r) => r.data);
};

export const getLogsInDateRange = (startDate: string, endDate: string) => {
  return axios
    .get(
      `/api/lead/logging/engagement/?created_at_after=${startDate}&created_at_before=${endDate}`
    )
    .then((r) => r.data);
};

export const updateLeadByLead = (lead: string, data: any) => {
  return axios.patch(`/api/lead/${lead}/`, data).then((r) => r.data);
};

export const setScheduledCall = (data: {
  scheduled_at: string;
  lead: string;
}) => {
  return axios
    .post(`/api/lead/internal/scheduled/call/`, data)
    .then((r) => r.data);
};

export const searchLeadsByCompany = (data: {
  company_id: number;
  sold_at_after: string;
  sold_at_before: string;
}) => {
  return axios
    .get(
      "/api/advisor/lead/?" +
        Object.keys(data)
          .map((key) => `${key}=${(data as any)[key]}`)
          .join("&")
    )
    .then((r) => r.data);
};
