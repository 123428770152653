import React from "react";

type Props = {
  onClick: (e: React.MouseEvent) => void;
  isLoaded?: boolean;
  title: string;
  colour:
    | "background-green"
    | "background-red"
    | "background-blue"
    | "background-yellow"
    | "background-dark-grey";
  textColor: "text-color-white" | "text-color-blue";
};

const Button = (props: Props) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      className={`button smallest close ${props.colour} ${props.textColor}`}
      disabled={props?.isLoaded ? !props.isLoaded : false}
    >
      {props?.isLoaded
        ? !props.isLoaded
          ? "Loading..."
          : props.title
        : props.title}
    </button>
  );
};

export default Button;
