import React, {useEffect, useState, createContext, ReactChild} from "react";
const SOCKET_URL = process.env.WEBSOCKET_URL || '';
const SOCKET_RECONNECTION_TIMEOUT = 10000;


const webSocket = new WebSocket(SOCKET_URL);

export const SocketContext = createContext(webSocket);

interface ISocketProvider {
    children: ReactChild;
}

export const SocketProvider = (props: ISocketProvider) => {
    const [ws, setWs] = useState<WebSocket>(webSocket);

    useEffect(() => {
        const onClose = () => {
            setTimeout(() => {
                if (!SOCKET_URL) return;
                setWs(new WebSocket(SOCKET_URL));
            }, SOCKET_RECONNECTION_TIMEOUT);
        };

        ws.addEventListener("close", onClose);

        return () => {
            ws.removeEventListener("close", onClose);
        };
    }, [ws, setWs]);

    return (
        <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
    );
};
