import { Notification } from "../types/Notification.type";
import { INotification } from "../services/Notifications";

export default [
  {
    name: "Mortgage value",
    id: "mortgage_value",
    options: {
      mv_all: {
        label: "All",
        value: {
          mortgage_value_min: undefined,
          mortgage_value_max: undefined,
        },
      },
      mv_below_100: {
        label: "Below £100k",
        value: {
          mortgage_value_min: 0,
          mortgage_value_max: 100000,
        },
      },
      mv_above_100: {
        label: "Above £100k",
        value: {
          mortgage_value_min: 100000,
          mortgage_value_max: 100000000,
        },
      },
      mv_above_150: {
        label: "Above £150k",
        value: {
          mortgage_value_min: 150000,
          mortgage_value_max: 100000000,
        },
      },
      mv_above_200: {
        label: "Above £200k",
        value: {
          mortgage_value_min: 100000,
          mortgage_value_max: 100000000,
        },
      },
      mv_above_300: {
        label: "Above £300k",
        value: {
          mortgage_value_min: 300000,
          mortgage_value_max: 100000000,
        },
      },
      mv_above_500: {
        label: "Above £500k",
        value: {
          mortgage_value_min: 500000,
          mortgage_value_max: 100000000,
        },
      },
    },
    selectedValue: (notification: INotification, options?: any) => {
      const min = notification.mortgage_value_min;
      const max = notification.mortgage_value_max;
      if (!min && !max) return "N/A";
      if (min === 0 && max === 100000) {
        return "mv_below_100";
      } else if (min === 100000 && max === 100000000) {
        return "mv_above_100";
      } else if (min === 150000 && max === 100000000) {
        return "mv_above_150";
      } else if (min === 200000 && max === 100000000) {
        return "mv_above_200";
      } else if (min === 300000 && max === 100000000) {
        return "mv_above_300";
      } else if (min === 500000 && max === 100000000) {
        return "mv_above_500";
      } else {
        return "mv_all";
      }
    },
  },
  {
    name: "LTV",
    id: "ltv",
    options: {
      ltv_all: {
        label: "All",
        value: {
          ltv_value_min: undefined,
          ltv_value_max: undefined,
        },
      },
      ltv_below_80: {
        label: "Below 80%",
        value: {
          ltv_value_min: 0,
          ltv_value_max: 80,
        },
      },
      ltv_below_70: {
        label: "Below 70%",
        value: {
          ltv_value_min: 0,
          ltv_value_max: 70,
        },
      },
    },
    selectedValue: (notification: Notification, options?: any) => {
      const min = notification.ltv_value_min;
      const max = notification.ltv_value_max;
      if (!min && !max) return "N/A";
      if (min === 0 && max === 70) {
        return "ltv_below_70";
      } else if (min === 0 && max === 80) {
        return "ltv_below_80";
      } else {
        return "ltv_all";
      }
    },
  },
  {
    name: "Mortgage purpose",
    id: "mortgage_purpose",
    options: {
      mp_all: {
        label: "All",
        value: {
          purpose: undefined,
        },
      },
      mp_BTL: {
        label: "Buy to let",
        value: {
          purpose: "BTL",
        },
      },
      mp_PUR: {
        label: "Purchase",
        value: {
          purpose: "PUR",
        },
      },
      mp_RM: {
        label: "Remortgage",
        value: {
          purpose: "RM",
        },
      },
      mp_FTB: {
        label: "First Time Buyer",
        value: {
          purpose: "FTB",
        },
      },
      mp_ER: {
        label: "Equity Release",
        value: {
          purpose: "ER",
        },
      },
    },
    selectedValue: (notification: Notification, options?: any) => {
      if (!notification.purpose) return "N/A";
      const prefix = "mp_";
      return prefix + notification.purpose;
    },
  },
  {
    name: "Location",
    id: "location",
    options: {
      loc_all: {
        label: "All",
        value: {
          location: undefined,
        },
      },
      loc_london: {
        label: "Greater London",
        value: {
          location: "Greater London",
        },
      },
      loc_ne: {
        label: "North East",
        value: {
          location: "North East",
        },
      },
      loc_nw: {
        label: "North West",
        value: {
          location: "North West",
        },
      },
      loc_em: {
        label: "East Midlands",
        value: {
          location: "East Midlands",
        },
      },
      loc_wm: {
        label: "West Midlands",
        value: {
          location: "West Midlands",
        },
      },
      loc_se: {
        label: "South East",
        value: {
          location: "South East",
        },
      },
      loc_eoe: {
        label: "East of England",
        value: {
          location: "East of England",
        },
      },
      loc_sw: {
        label: "South West",
        value: {
          location: "South West",
        },
      },
      loc_wal: {
        label: "Wales",
        value: {
          location: "Wales",
        },
      },
      loc_sco: {
        label: "Scotland",
        value: {
          location: "Scotland",
        },
      },
      loc_ni: {
        label: "Northern Ireland",
        value: {
          location: "Northern Ireland",
        },
      },
    },
    selectedValue: (notification: Notification, options?: any) => {
      if (!notification.location || !options) return "N/A";
      if (options) {
        return Object.keys(options).find(
          (key) => options[key] === notification.location
        );
      } else return "N/A";
    },
  },
  {
    name: "Credit Status",
    id: "credit_status",
    options: {
      cs_all: {
        label: "All",
        value: {
          credit_score: undefined,
        },
      },
      cs_bad: {
        label: "Bad",
        value: {
          credit_score: "Bad",
        },
      },
      cs_fair: {
        label: "Fair",
        value: {
          credit_score: "Fair",
        },
      },
      cs_good: {
        label: "Good",
        value: {
          credit_score: "Good",
        },
      },
      cs_excellent: {
        label: "Excellent",
        value: {
          credit_score: "Excellent",
        },
      },
    },
    selectedValue: (notification: Notification, options?: any) => {
      if (!notification.credit_score) return "N/A";
      const prefix = "cs_";
      return prefix + notification.credit_score;
    },
  },
];
