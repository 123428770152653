import React, { useEffect } from "react";

type Props = {
  breadcrumbs?: string[];
  title?: string;
  children: JSX.Element;
};

const PageTemplate = ({ breadcrumbs, title, children }: Props) => {
  useEffect(() => {
    document.title = title + " | Giving Mortgages" || "Giving Mortgages";
  }, []);

  return (
    <div className="column" id="dashboard">
      <div className="module-mobile">
        <div className="credit-stock">
          <ul>
            <li>
              <a className="button smallest background-yellow" href="/wallet">
                <i className="fa-solid fa-coins"></i> 0<span> Credits</span>
              </a>
            </li>
            <li className="buy_more">
              <a className="button smallest" href="/wallet">
                Buy more<span> credits</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="module-heading">
          <div className="breadcrumbs">
            {breadcrumbs && (
              <ul>
                {breadcrumbs.map((breadcrumb, index) => {
                  if (index === 0) {
                    return (
                      <li key={index}>
                        <a href="#">{breadcrumb}</a>
                      </li>
                    );
                  } else {
                    return <li key={index}>{breadcrumb}</li>;
                  }
                })}
              </ul>
            )}
          </div>

          {title && <h1>{title}</h1>}
        </div>
      </div>
      {children}
    </div>
  );
};

export default PageTemplate;
