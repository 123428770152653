import React from "react";
import KPIs from "../../components/KPIs";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useAppSelector } from "../../hooks";

const CallcenterKPIPage = () => {
  const { user } = useAppSelector((state) => state.user);
  if (!user) {
    return <LoadingSpinner text={"Loading Portal"} />;
  }
  return <KPIs />;
};

export default CallcenterKPIPage;
