import axios from "axios";

export type SaveNoteRequest = {
  lead: string;
  note: string;
};

export const saveNote = (data: SaveNoteRequest, noteType?: "internal") => {
  const url =
    noteType === "internal" ? "/api/lead/internal/note/" : "/api/lead/note/";

  return axios.post(url, data).then((r) => r.data);
};

export const getNotesByLead = (leadId: string, noteType?: "internal") => {
  return axios
    .get(
      `/api/lead/${
        noteType === "internal" ? "internal/" : ""
      }note/?lead__id=${leadId}`
    )
    .then((r) => r.data);
};
