import React, { useState } from "react";
import { Bar, Pie } from "@ant-design/plots";
import moment from "moment";

import "./styles.scss";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import LoadingSpinner from "../LoadingSpinner";
import useKpiCharts from "../../hooks/useKpiCharts";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Module from "../Module";
import pages from "../../constants/pages";

const KPIs = () => {
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [
    isLoaded,
    logsByUser,
    logsByAction,
    totalLeadsGenerated,
    totalLeadsPurchasedByCompany,
    totalDispositions,
    statusByGeneratedLeadForUKMC,
    statusByGeneratedLeadForGM,
  ] = useKpiCharts({
    start: startDate,
    end: endDate,
  });

  const stackedBarChartByDisposition = {
    data: logsByUser.reverse(),
    isStack: true,
    xField: "value",
    yField: "name",
    seriesField: "type",
    label: {
      position: "middle",

      layout: [
        {
          type: "interval-adjust-position",
        },

        {
          type: "interval-hide-overlap",
        },

        {
          type: "adjust-color",
        },
      ],
    },
  };
  const pieChartByDisposition = {
    data: logsByAction,
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const pieChartByLeadsGM = {
    data: statusByGeneratedLeadForGM,
    width: 500,
    height: 400,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const pieChartByLeadsUKMC = {
    data: statusByGeneratedLeadForUKMC,
    width: 500,
    height: 400,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  if (!isLoaded) {
    return (
      <LoadingSpinner isLoading={!isLoaded} text={"Grabbing your stats"} />
    );
  }

  return (
    <Module
      title={"Internal lead data"}
      body={
        <div className={"align-items-center flex-container"}>
          <button
            className={"button smallest background-green"}
            onClick={() => {
              const destination = pages.find(
                (page) => page.id === "callcenter"
              )?.url;
              destination && window.location.replace(destination);
            }}
          >
            Back to portal
          </button>
          <div>
            <ul className={"quick-stats-list"}>
              <li>Total Dispositions: {totalDispositions}</li>
              <li>Total Leads Generated: {totalLeadsGenerated}</li>
              <li>Total Leads bought: {totalLeadsPurchasedByCompany}</li>
            </ul>
          </div>
        </div>
      }
    >
      <div className="kpi-page">
        <div className="spacer"></div>
        <Tabs>
          <TabList>
            <Tab>By Dispositions</Tab>
            <Tab>By Generated Leads</Tab>
          </TabList>
          <TabPanel>
            <div className="kpi-list">
              <div className="kpi">
                <Bar {...stackedBarChartByDisposition} />
              </div>
              <div className="kpi">
                <Pie {...pieChartByDisposition} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="kpi-list">
              <div className="kpi">
                <div className="kpi-title">Leads for Giving Mortgages</div>
                <Pie {...pieChartByLeadsGM} />
              </div>
              <div className="kpi">
                <div className="kpi-title">Leads for UKMC</div>
                <Pie {...pieChartByLeadsUKMC} />
              </div>
            </div>
          </TabPanel>
        </Tabs>

        <div className="spacer"></div>
        <div className="kpi-inputs">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDateTimePicker
              className={"margin-right"}
              renderInput={(props) => <TextField {...props} />}
              label="From"
              value={startDate}
              disableFuture
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
            />
            <MobileDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="To"
              value={endDate}
              disableFuture
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    </Module>
  );
};

export default KPIs;
