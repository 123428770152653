import React, { useEffect } from "react";
import { setBestTimeToCall } from "../../redux/callcenter";
import { getBestTimesToCall } from "../../services/BestTimeToContact";
import { getUserData } from "../../services/Users";
import { setUser } from "../../redux/user";
import { useAppDispatch } from "../../hooks";

const DataProvider = (props: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUserData()
      .then((data) => {
        dispatch(setUser(data));
      })
      .catch((e) => {
        console.log(e);
        alert("Error while fetching user data");
        //window.location.replace("/login?next=" + window.location.pathname);
      });

    getBestTimesToCall()
      .then((rsp) => {
        dispatch(setBestTimeToCall(rsp));
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  return <>{props.children}</>;
};

export default DataProvider;
