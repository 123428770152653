import React, { useEffect, useRef, useState } from "react";
import { default as ExternalDatatable } from "react-data-table-component";
import classNames from "classnames";

import "./styles.scss";
import LoadingSpinner from "../LoadingSpinner";

interface Props<T> {
  data: any;
  columns: any;
  progressPending: boolean;
  expandableRows?: boolean;
  expandableRowsComponent?: any;
  loadingText?: string;
  disableSocket?: boolean;
  expandableRowDisabled?: (row: any) => boolean;
}

const Table = (props: Props<any>) => {
  const {
    data,
    columns,
    progressPending,
    expandableRows,
    expandableRowsComponent,
    loadingText,
    expandableRowDisabled,
  } = props;
  const scrollerRef = useRef<HTMLDivElement>();
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (scrollerRef?.current) {
      scrollerRef.current.addEventListener("scroll", () => {});
    }
  }, [scrollerRef]);

  window.addEventListener("scroll", () => {
    const dtable = scrollerRef?.current?.firstElementChild;
    if (dtable) {
      dtable.addEventListener("scroll", () => {
        if (dtable.scrollLeft > 10 && hasScrolled == false) {
          setHasScrolled(true);
        }
      });
    }
  });
  return (
    <div
      className={classNames("column module scrollable marketplace dragging", {
        scrolled: hasScrolled,
      })}
      ref={scrollerRef as any}
    >
      <ExternalDatatable
        columns={columns}
        data={data}
        progressPending={progressPending}
        progressComponent={LoadingSpinner({
          text: loadingText ?? "Loading Leads",
        })}
        pagination
        expandableRowsComponent={expandableRowsComponent}
        expandableIcon={{
          collapsed: (
            <svg
              fill="currentColor"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
              <path d="M0-.25h24v24H0z" fill="none"></path>
            </svg>
          ),
          expanded: (
            <svg
              fill="currentColor"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                color={"white"}
                d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"
              ></path>
              <path d="M0-.75h24v24H0z" fill="none"></path>
            </svg>
          ),
        }}
        expandableRows={expandableRows ? expandableRows : false}
        expandableRowExpanded={(row: any) => row?.defaultExpanded}
        expandableRowDisabled={
          expandableRowDisabled
            ? expandableRowDisabled
            : (row: any) => (row.lead_status === "Sold" ? true : false)
        }
        highlightOnHover={true}
        pointerOnHover={true}
        expandOnRowClicked={true}
        paginationPerPage={15}
        onChangePage={(page) => {
          console.log(page);
        }}
        paginationRowsPerPageOptions={[15, 25, 50, 100]}
        customStyles={{
          headRow: {
            style: {
              borderBottom: "none",
              minHeight: "auto",
            },
          },
          headCells: {
            style: {
              backgroundColor: "#F3F6F9",
              borderRight: "1px solid #D6E0EA",
              borderBottom: "2px solid #E4EBF1",
              fontSize: 12,
              fontWeight: 700,
              padding: 10,
              color: "#081F49",
              whiteSpace: "pre-line",
            },
          },
          cells: {
            style: {
              borderRight: "1px solid #D6E0EA",
              borderBottom: "1px solid #D6E0EA",
              color: "#081F49",
            },
          },
          rows: {
            style: {
              borderBottom: "none",
            },
          },
          pagination: {
            style: {
              borderTop: "none",
            },
          },
          responsiveWrapper: {
            style: {
              borderRadius: 0,
            },
          },
          expanderButton: {
            style: {
              backgroundColor: "#09BC8A",
              borderRadius: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default Table;
