import React, { useState } from "react";
import callcenterTabs from "../../constants/callcenterTabs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./styles.scss";
import { ExpanderComponentProps } from "react-data-table-component";
import LeadLogView from "../LeadLogView";
import { TableBySearchTerm } from "../PaginatedTable";
import Pages from "../../constants/pages";

type Props = {
  onTabChange: (tabId: string) => void;
  searchTerm?: string;
  tableColumns: any;
  tableData: any[];
  pageId: string;
};

const CallcenterTabs = (props: Props) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({
    data,
  }) => {
    return <LeadLogView lead_id={data?.lead_id} />;
  };
  const page = Pages.find((page) => page.id === props.pageId);

  return (
    <>
      <Tabs
        selectedIndex={selectedTabIndex}
        onSelect={(tabId) => {
          setSelectedTabIndex(tabId);
          props.onTabChange(callcenterTabs[tabId].id);
        }}
      >
        <TabList>
          {callcenterTabs.map((tab) => (
            <Tab key={tab.id}>{tab.title}</Tab>
          ))}
        </TabList>

        {callcenterTabs.map((tab) => (
          <TabPanel key={tab.id}>
            <TableBySearchTerm
              data={[]}
              dataRequest={tab.requestFunc}
              columns={props.tableColumns}
              filterFunc={tab.filter}
              stateFunc={tab.stateFunc}
              searchTerm={props.searchTerm}
              expandableRows={true}
              expandOnRowClicked={true}
              expandableRowDisabled={(row) =>
                row?.lead_status === "Sold" || row?.engagementCount === 0
              }
              expandableRowsComponent={ExpandedComponent}
              url={page?.url ?? ''}
            />
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};

export default CallcenterTabs;
