import React, { useCallback, useEffect, useState } from "react";
import Table, { TableProps } from "react-data-table-component";
import { ILeadInterface } from "../../interfaces/ILead.interface";
import { getTableData } from "../../pages/Callcenter/helpers";
import LoadingSpinner from "../LoadingSpinner";
import { debounce } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";

interface Props extends TableProps<any> {
  dataRequest: (
    page: number,
    pageSize: number,
    searchTerm?: string
  ) => Promise<any>;
  searchTerm?: string;
  filterFunc?: (leads: ILeadInterface[]) => ILeadInterface[];
  stateFunc?: (leads: ILeadInterface[]) => any;
  url: string;
}

const PaginatedTable = (props: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { currentPage, pageSize } = useParams();
  const { cache, messages } = useAppSelector((state: any) => state.websocket);
  const [paginationRowsPerPage, setPaginationRowsPerPage] = useState(
    pageSize ? parseInt(pageSize) : 15
  );
  const [paginationPage, setPaginationPage] = useState(
    currentPage ? parseInt(currentPage) : 1
  );
  const [requestData, setRequestData] = useState<{
    count: number;
    next: string;
    previous: string;
    results: ILeadInterface[];
  } | null>(null);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const updateUrl = () => {
    navigation(`/portal/callcenter/${paginationPage}/${paginationRowsPerPage}`);
  };

  const requestSearchById = (searchTerm: string) => {
    props
      .dataRequest(paginationPage, paginationRowsPerPage, searchTerm)
      .then((data) => {
        setRequestData({
          ...data,
          results: getTableData(data.results, cache, messages),
        });
        props.stateFunc && dispatch(props.stateFunc(data.results));
        updateUrl();
        setIsLoaded(true);
      });
  };

  const callRequestData = () => {
    props
      .dataRequest(paginationPage, paginationRowsPerPage, props.searchTerm)
      .then((data) => {
        setRequestData({
          ...data,
          results: getTableData(data.results, cache, messages),
        });
        props.stateFunc && dispatch(props.stateFunc(data.results));
        updateUrl();
        setIsLoaded(true);
      });
  };

  const verify = useCallback(
    debounce((search) => {
      if (search) {
        requestSearchById(search);
      } else {
        callRequestData();
      }
    }, 750),
    []
  );

  useEffect(() => {
    setIsLoaded(false);
    if (props.searchTerm) {
      verify(props.searchTerm);
    } else {
      callRequestData();
    }
  }, [paginationPage, paginationRowsPerPage, props.searchTerm]);

  return (
    <Table
      {...props}
      data={
        (props.filterFunc
          ? props.filterFunc(requestData?.results || [])
          : requestData?.results) as any
      }
      progressPending={!isLoaded}
      progressComponent={<LoadingSpinner text={"Fetching leads"} />}
      paginationTotalRows={requestData?.count}
      paginationServer
      pagination
      expandableIcon={{
        collapsed: (
          <svg
            fill="currentColor"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
            <path d="M0-.25h24v24H0z" fill="none"></path>
          </svg>
        ),
        expanded: (
          <svg
            fill="currentColor"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              color={"white"}
              d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"
            ></path>
            <path d="M0-.75h24v24H0z" fill="none"></path>
          </svg>
        ),
      }}
      paginationDefaultPage={currentPage ? parseInt(currentPage) : 1}
      paginationPerPage={pageSize ? parseInt(pageSize) : 15}
      paginationRowsPerPageOptions={[15, 25, 50, 100]}
      onChangeRowsPerPage={(currentRowsPerPage) => {
        setPaginationRowsPerPage(currentRowsPerPage);
      }}
      customStyles={{
        headRow: {
          style: {
            borderBottom: "none",
            minHeight: "auto",
          },
        },
        headCells: {
          style: {
            backgroundColor: "#F3F6F9",
            borderRight: "1px solid #D6E0EA",
            borderBottom: "2px solid #E4EBF1",
            fontSize: 12,
            fontWeight: 700,
            padding: 10,
            color: "#081F49",
            whiteSpace: "pre-line",
          },
        },
        cells: {
          style: {
            borderRight: "1px solid #D6E0EA",
            borderBottom: "1px solid #D6E0EA",
            color: "#081F49",
          },
        },
        rows: {
          style: {
            borderBottom: "none",
          },
        },
        pagination: {
          style: {
            borderTop: "none",
          },
        },
        responsiveWrapper: {
          style: {
            borderRadius: 0,
          },
        },
        expanderButton: {
          style: {
            backgroundColor: "#09BC8A",
            borderRadius: 0,
          },
        },
      }}
      onChangePage={(page) => {
        setPaginationPage(page);
      }}
    />
  );
};

export default PaginatedTable;
