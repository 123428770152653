import React from "react";

import "./styles.scss";
import classNames from "classnames";

const Modal = ({ onClose, children, isSmall = false } = props) => {
  return (
    <div
      className={"modal"}
      style={{
        display: "block",
      }}
    >
      <div
        className={classNames("wrap", {
          smallest: isSmall,
        })}
      >
        <div className="flex-container">
          <div className="column">
            <div
              className="close"
              onClick={() => {
                onClose();
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
