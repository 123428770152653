import axios from "axios";

export const getUserData = () => {
  return axios.get("/user/get").then((r) => r.data);
};

export const getUsersByCompanies = (comapnyId: number) => {
  return axios.get("/api/companies/" + comapnyId + "/").then((r) => r.data);
};

export const addAdvisorToCompany = (data: {
  first_name: string;
  last_name: string;
  email: string;
  password1: string;
  password2: string;
  phonenumber: string;
  individual_fca_number: string;
  location: string;
  job_type: string;
}) => {
  return axios.post("/api/advisor_creation/", data).then((r) => r.data);
};
