import React, { useState } from "react";
import classNames from "classnames";

import "./styles.scss";
import Pages from "../../constants/pages";
import { checkPermission } from "../../helpers";
import { useAppSelector } from "../../hooks";

const Navigation = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAppSelector((state) => state.user);

  return (
    <div
      className={classNames("column", {
        open: isOpen && isMobile,
      })}
      id="dashboard_navigation"
    >
      <nav className="navigation">
        <ul>
          <li
            className={classNames("menu-trigger", {
              active: isMobile && isOpen,
            })}
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
          >
            <a href="">Menu</a>
          </li>
        </ul>
        <ul
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={classNames("menu-items", {
            isOpen: isMobile ? isOpen : false,
          })}
        >
          {Pages.filter(
            (x) =>
              !x.hidden && checkPermission(user?.groups ?? [], x?.permissions)
          ).map((page, index) => {
            return (
              <li
                style={{ flex: 1 }}
                className={classNames(page?.class, {
                  active: window.location.pathname == page?.url,
                })}
                key={index}
              >
                <a href={page?.url}>{page?.name}</a>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
