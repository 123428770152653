import React from "react";
import {
  createNotification,
  INotification,
  NotificationMap,
} from "../../services/Notifications";
import { setNotificationModal } from "../../redux/callcenter";
import { useAppDispatch, useAppSelector } from "../../hooks";

const CreateNotification = (props: { closeModal: () => void }) => {
  const { notificationModal } = useAppSelector((state) => state?.callcenter);
  const cleanedNotification = Object.keys(notificationModal).filter(
    (property: any) =>
      notificationModal[property] !== null &&
      notificationModal[property] !== undefined
  );

  const dispatch = useAppDispatch();

  const submitNotification = () => {
    const notification = Object.fromEntries(
      Object.entries(notificationModal).filter(
        ([_, v]) => v != null || v != undefined
      )
    );
    createNotification(notification as INotification)
      .then((res: any) => {
        console.log(res);
      })
      .then(() => {
        props.closeModal();
      });
  };

  const hasFilters =
    [
      "mortgage_value_min",
      "mortgage_value_max",
      "ltv_value_min",
      "ltv_value_max",
      "income_multi_value_min",
      "income_multi_value_max",
      "purpose",
      "location",
      "credit_score",
    ].filter((filter) => cleanedNotification.includes(filter)).length > 0;
  const hasError =
    !hasFilters ||
    !notificationModal?.name ||
    notificationModal?.frequency == null ||
    notificationModal?.frequency == "";
  return (
    <form>
      <p className="h3 margin-half">Create notification</p>
      {hasFilters && (
        <div className="module background-dark-grey">
          <div className="module-heading">
            <div className="basic-list">
              <p className={"h6"}>You're selected filters</p>
              <ul>
                {cleanedNotification.map((property: any) => {
                  if ((NotificationMap as any)[property]) {
                    return (
                      <li key={property}>
                        <strong>{(NotificationMap as any)[property]}: </strong>
                        {notificationModal[property]}
                      </li>
                    );
                  } else return null;
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
      <input type="hidden" name="filters" id="notification-filters" value="" />
      <div className="field call_meeting_date">
        <label className="h6 margin-none">Name the notification</label>
        <p className="margin-half text-size-smaller">
          This is how it will appear in your notifications list
        </p>
        <input
          type="text"
          name="notification_name"
          placeholder="Name the notification"
          onChange={(e) =>
            dispatch(setNotificationModal({ name: e.target.value }))
          }
          required
        />
        <span className="error">
          <i className="fa-solid fa-triangle-exclamation"></i> This field is
          required
        </span>
      </div>
      <div className="field notification_frequency">
        <label className="h6 margin-none">Notification frequency</label>
        <p className="text-size-smaller margin-half">
          Select how often you would like to be notified when a lead that
          matches this criteria enters the marketplace
        </p>
        <select
          name="notification_frequency"
          required
          onChange={(e) => {
            dispatch(setNotificationModal({ frequency: e.target.value }));
          }}
        >
          <option value="">Make a selection</option>
          <option value="instant">Notify me straight away</option>
          <option value="1h">Notify me hourly</option>
          <option value="morning">Notify me every morning</option>
          <option value="afternoon">Notify me every afternoon</option>
          <option value="evening">Notify me every evening</option>
        </select>
        <span className="error">
          <i className="fa-solid fa-triangle-exclamation"></i> This field is
          required
        </span>
      </div>
      <div className="spacer half"></div>
      <div className="field">
        <div className="spacer half"></div>
        <button
          type="submit"
          id="create_notification"
          className="button larger submit float-left"
          disabled={hasError}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!hasError) {
              submitNotification();
            }
          }}
        >
          Create notification
        </button>
        {!hasFilters && (
          <span
            className="error"
            style={{ display: "block" }}
            id="cant_create_notification_error"
          >
            <i className="fa-solid fa-triangle-exclamation"></i> You must select
            some filters to create a notification
          </span>
        )}
      </div>
    </form>
  );
};

export default CreateNotification;
