import React from "react";
import { resolveFromFields } from "../../helpers";

export const OverviewFields = (dynamoData) => {
  return [
    {
      name: "Name",
      value: () => {
        return [
          resolveFromFields("RawCreateData.person_1_fname", dynamoData),
          resolveFromFields("RawCreateData.person_1_lname", dynamoData),
        ].join(" ");
      },
      inputProps: {
        disabled: true,
      },
    },
    {
      name: "D.O.B",
      path: "RawCreateData.person_1_dob",
      value: () => resolveFromFields("RawCreateData.person_1_dob", dynamoData),
    },
    {
      name: "Mobile Number",
      path: "RawCreateData.person_1_telephone_number",
      value: () =>
        resolveFromFields(
          "RawCreateData.person_1_telephone_number",
          dynamoData
        ),
    },
    {
      name: "Credit Score",
      path: "credit_check.credit_score",
      value: () => resolveFromFields("credit_check.credit_score", dynamoData),
      inputProps: {
        disabled: true,
      },
    },
    {
      name: "Email Address",
      path: "RawCreateData.person_1_email_address",
      value: () =>
        resolveFromFields("RawCreateData.person_1_email_address", dynamoData),
    },
    {
      name: "Income Multiplier",
      value: () => {
        const person1Income = resolveFromFields(
          "RawCreateData.person_1_total_annual_income",
          dynamoData
        );
        const person2Income = resolveFromFields(
          "RawCreateData.person_2_total_annual_income",
          dynamoData
        );
        const mortageAmount = resolveFromFields(
          "RawCreateData.mortgage_amount",
          dynamoData
        ).replace(",", "");

        const person1isInt = typeof person1Income === "number";

        if (person2Income) {
          const person2isInt = typeof person2Income === "number";
          return (
            (person1isInt
              ? person1Income
              : parseInt(person1Income?.replace(",", "")) + person2isInt
              ? person2Income
              : parseInt(person2Income?.replace(",", ""))) /
            parseInt(mortageAmount)
          ).toFixed(2);
        } else {
          return (
            (person1isInt
              ? person1Income
              : parseInt(person1Income?.replace(",", ""))) /
            parseInt(mortageAmount)
          ).toFixed(2);
        }
      },
      inputProps: {
        disabled: true,
      },
    },
    {
      name: "Reason for enquiry",
      path: "MortgageDetails.mortgage_purpose",
      value: () =>
        resolveFromFields("MortgageDetails.mortgage_purpose", dynamoData),
      inputProps: {
        as: "select",
        options: {
          FTB: "First Time Buyer",
          NB: "New Build",
          SO: "Shared Ownership",
          RTB: "Right To Buy",
          ER: "Equity Release",
          HTB: "Help To Buy",
          CM: "Contractor Mortgage",
          BTL: "Buy To Let",
          RM: "Remortgage",
          PUR: "Mortgage",
        },
      },
    },
    {
      name: "Mortgage Value",
      path: "RawCreateData.mortgage_amount",
      value: () =>
        resolveFromFields("RawCreateData.mortgage_amount", dynamoData),
    },
    {
      name: "Person 1 Income",
      path: "RawCreateData.person_1_total_annual_income",
      value: () =>
        resolveFromFields(
          "RawCreateData.person_1_total_annual_income",
          dynamoData
        ),
    },
    {
      name: "Person 2 Income",
      path: "RawCreateData.person_2_total_annual_income",
      value: () =>
        resolveFromFields(
          "RawCreateData.person_2_total_annual_income",
          dynamoData
        ) || 0,
    },
    {
      name: "Home Address",
      value: () => {
        const address = [
          "RawCreateData.person_1_current_address_thoroughfare",
          "RawCreateData.person_1_current_address_post_town",
          "RawCreateData.person_1_current_address_county",
          "RawCreateData.person_1_current_address_postcode",
        ]
          .map((path) => resolveFromFields(path, dynamoData))
          .join(", \n");
        return address;
      },
      inputProps: {
        as: "textarea",
        rows: 1,
        disabled: true,
      },
    },
  ];
};
