import React from "react";
import Field, { FieldProps } from "../Field";

type CheckboxListProps = {
  options: FieldProps[];
};

const CheckboxList = ({ options }: CheckboxListProps) => {
  return (
    <ul className="options full">
      {options.map((option, index) => (
        <li key={index}>
          <Field {...option} />
        </li>
      ))}
    </ul>
  );
};

export default CheckboxList;
