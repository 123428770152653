import React, { useEffect, useState } from "react";
import "./styles.scss";
import LoadingSpinner from "../LoadingSpinner";
import { getMarketplaceLeadDetails } from "../../services/Leads";
import Button from "../Button";

type Props = {
  leadId: string;
};

const Index = ({ leadId }: Props) => {
  const [lead, setLead] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (lead == undefined) {
      getMarketplaceLeadDetails(leadId).then((lead) => {
        setLead(lead);
        setIsLoading(false);
      });
    }
  });

  if (isLoading) {
    return LoadingSpinner({ text: "Loading lead details" });
  }

  return (
    <div className={"lead-details loaded"}>
      <div className="applicant-info">
        <p className="lead-details-title">Applicant information</p>
        <ul>
          <li>
            <strong>Name:</strong>{" "}
            <span className="text-align-right">{lead?.name}</span>
          </li>
          <li>
            <strong>Telephone:</strong> {lead?.telephone}
          </li>
          <li>
            <strong>Area:</strong> {lead?.area}
          </li>
          <li>
            <strong>Age:</strong> {lead?.age}
          </li>
          <li>
            <strong>Income:</strong> {lead?.income}
          </li>
          <li>
            <strong>Credit status:</strong> {lead?.credit_status}
          </li>
          {lead?.cert_url && (
            <li>
              <Button
                onClick={() => {
                  window.open(lead.cert_url, "_blank");
                }}
                isLoaded={true}
                title={"View Certificate"}
                colour={"background-green"}
                textColor={"text-color-white"}
              />
            </li>
          )}
        </ul>
      </div>
      <div className="mortgage-info">
        <p className="lead-details-title">Mortgage information</p>
        <ul>
          <li>
            <strong>Mortgage purpose:</strong> {lead?.purpose}
          </li>
          <li>
            <strong>Mortgage type:</strong> Residential
          </li>
          <li>
            <strong>Property value:</strong> {lead?.value}
          </li>
          <li>
            <strong>Loan amount:</strong> {lead?.loan_amount}
          </li>
          <li>
            <strong>Lead identifier:</strong>{" "}
            <span className={"selectable"}>{leadId}</span>
          </li>
        </ul>
      </div>
      <div className="property-info">
        <p className="lead-details-title">Property information</p>
        <ul>
          <li className={"text-capitalize"}>
            <strong>Type:</strong> {lead?.property_type}
          </li>
          <li>
            <strong>Construction:</strong> {lead?.property_construction}
          </li>
          <li>
            <strong>Built:</strong> {lead?.property_built}
          </li>
          <li>
            <strong>Tenure:</strong> {lead?.tenure}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Index;
