import React from "react";
import Button from "../Button";
import Modal from "../Modal";

type Props = {
  type: "lead_already_sold";
};

const getErrorMessage = (type: string) => {
  if (type === "lead_already_sold") {
    return (
      <div className="text-center">
        <h1 className="text-4xl font-bold">Too Slow...</h1>
        <p className="text-xl">
          Unfortunately this lead has already been sold to another buyer
        </p>
        <Button
          onClick={() => {
            window.location.replace("/marketplace");
          }}
          isLoaded={true}
          title={"Back to marketplace"}
          colour={"background-green"}
          textColor={"text-color-white"}
        />
      </div>
    );
  } else return;
};

const ErrorMessagePage = ({ type }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(true);
  return (
    <Modal
      onClose={() => {
        setIsModalOpen(false);
      }}
      isSmall={true}
    >
      {getErrorMessage(type)}
    </Modal>
  );
};

export default ErrorMessagePage;
