import React, { useCallback, useEffect, useRef, useState } from "react";

import "./styles.scss";

import Module from "../../components/Module";
import ReactTooltip from "react-tooltip";
import { getCallcenterLeadStatus } from "../../helpers";
import moment from "moment";
import { useModal } from "react-modal-hook";
import { setSelectedLead } from "../../redux/callcenter";
import Modal from "../../components/Modal";
import EditLead from "../../components/EditLead";
import ListNotes from "../../components/ListNotes";
import DataProvider from "../../components/DataProvider/DataProvider";
import Search from "../../components/Search";
import CallcenterTabs from "../../components/CallcenterTabs";
import pages from "../../constants/pages";
import { setCache, setMessages } from "../../redux/websocket";
import { useSocket } from "../../hooks/useSocket";
import { getCache } from "../../services/RedisCache";
import { logLeadEngagement, updateLeadByLead } from "../../services/Leads";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";

const CallcenterPage = ({ pageId }: { pageId: string }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useAppSelector((state: any) => state.user);
  const dispatch = useAppDispatch();
  const socket = useSocket();
  const editProps = {
    closeModal: () => {
      dispatch(setSelectedLead(""));
      hideModal();
    },
  };

  const navigate = useNavigate();

  useEffect(() => {
    getCache()
      .then((rsp) => dispatch(setCache(rsp)))
      .catch((err) => alert(err));
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row: any) => `${row?.first_name} ${row?.last_name}`,
    },
    {
      name: "Identifier",
      selector: (row: any) => (
        <span className={"selectable"}>{row?.lead_id}</span>
      ),
    },
    {
      name: "Mortgage type",
      sortable: false,
      selector: (row: any) => row?.mortgage_type,
    },
    {
      name: "Generated/Qualified/Sold",
      selector: (row: any) => {
        if (row?.sold_at) {
          return <span className={"text-color-red"}>{row?.sold_at}</span>;
        } else if (row?.qualified_at) {
          return (
            <span className={"text-color-green"}>{row?.qualified_at}</span>
          );
        } else {
          return row.generated_friendly;
        }
      },
      sortable: false,
      sortFunction: (rowA: any, rowB: any) => {
        const a = Date.parse(rowA.generated) || Date.parse(rowA.generated);
        const b = Date.parse(rowB.generated) || Date.parse(rowB.generated);

        if (a > b) {
          return 1;
        }

        if (b > a) {
          return -1;
        }

        return 0;
      },
    },
    {
      name: "Status",
      sortable: false,
      sortFunction: (rowA: any, rowB: any) => {
        const a =
          Date.parse(rowA.callback_schedule_for) || Date.parse(rowA.created_at);
        const b =
          Date.parse(rowB.callback_schedule_for) || Date.parse(rowB.created_at);

        if (a > b) {
          return 1;
        }

        if (b > a) {
          return -1;
        }

        return 0;
      },
      selector: (row: any) => {
        if (row?.callback_schedule_for) {
          return (
            <>
              <ReactTooltip id={row.lead_id} type="info">
                {moment(row.callback_schedule_for).format("DD/MM/YYYY HH:mm A")}
              </ReactTooltip>
              <a data-tip data-for={row.lead_id}>
                {getCallcenterLeadStatus(row.lead_status, row.lead_id)}
              </a>
            </>
          );
        } else {
          return getCallcenterLeadStatus(row?.lead_status, row?.lead_id);
        }
      },
    },
    {
      name: "Internal Notes",
      sortable: false,
      selector: (row: any) => {
        if (row?.notes?.filter((note: any) => note?.internal)?.length <= 0) {
          return <></>;
        }
        return (
          <span
            className="status status-btn background-darkest-grey"
            onClick={() => {
              dispatch(setSelectedLead(row?.lead_id));
              showNotes();
            }}
          >
            See Notes
          </span>
        );
      },
    },
    {
      name: "",
      selector: (row: any) => {
        if (
          !row.lead_status.startsWith("Opened") &&
          row.lead_status != "Incoming"
        ) {
          if (
            row.delay ||
            (row?.callback_schedule_for &&
              moment(row?.callback_schedule_for).subtract(15, "minutes") >
                moment())
          ) {
            return <></>;
          } else {
            if (row?.deleted_at) {
              return (
                <span
                  className="status status-btn background-blue"
                  onClick={() => {
                    updateLeadByLead(row.lead_id, { deleted_at: null })
                      .then(() => {
                        logLeadEngagement({
                          lead: row.lead_id,
                          type: "update",
                          action: "update_lead",
                          log: "Lead restored",
                        })
                          .then(() => {
                            window.location.reload();
                          })
                          .catch((err) =>
                            alert("Error logging lead engagement")
                          );
                      })
                      .catch((e) => alert(e));
                  }}
                >
                  Restore
                </span>
              );
            } else {
              return (
                <span
                  className="status status-btn background-blue"
                  onClick={() => {
                    dispatch(setSelectedLead(row?.lead_id));
                    showModal();
                  }}
                >
                  View
                </span>
              );
            }
          }
        } else {
          return <></>;
        }
      },
    },
    {
      name: "Call Count",
      sortable: false,
      selector: (row: any) => row?.engagementCount,
    },
    {
      name: "Last Call",
      sortable: false,
      selector: (row: any) =>
        row?.last_call && moment(row.last_call).format("DD/MM/YYYY HH:mm A"),
    },
  ];

  const [showModal, hideModal] = useModal(() => {
    const editLeadRef = useRef<any>();
    return (
      <Modal
        onClose={() => {
          editLeadRef.current.closeSocketLead();
          dispatch(setSelectedLead(""));
          hideModal();
        }}
      >
        <EditLead ref={editLeadRef} {...editProps} />
      </Modal>
    );
  });

  const [showNotes, hideNotes] = useModal(() => {
    return (
      <Modal
        onClose={() => {
          dispatch(setSelectedLead(""));
          hideNotes();
        }}
      >
        <p className="h3">Internal Notes</p>
        <ListNotes type={"internal"} />
      </Modal>
    );
  });

  const onMessage = useCallback(({ data }: any) => {
    const rspData = JSON.parse(data);
    dispatch(setMessages(rspData));
  }, []);

  useEffect(() => {
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);

  return (
    <DataProvider>
      <Module
        title={"Internal lead data"}
        body={
          <div className={"align-items-center flex-container"}>
            {user.groups.includes("Callcenter Manager") ? (
              <button
                className={"button smallest background-green"}
                onClick={() => {
                  const destination = pages.find(
                    (page) => page.id === "callcenter-kpi"
                  )?.url;
                  destination && window.location.replace(destination);
                }}
              >
                View KPIs
              </button>
            ) : (
              <div></div>
            )}
            <Search
              onSearch={(value: string) => {
                setSearchTerm(value);
              }}
            />
          </div>
        }
      >
        <>
          <CallcenterTabs
            onTabChange={() => {
              navigate(`/portal/callcenter/`);
            }}
            tableColumns={columns}
            tableData={[]}
            searchTerm={searchTerm}
            pageId={pageId}
          />
        </>
      </Module>
    </DataProvider>
  );
};

export default CallcenterPage;
