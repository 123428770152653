import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { setLeadUpdates } from "../../redux/callcenter";
import { OverviewFields } from "./OverviewFields";
import EditableInput from "../EditableInput";
import ListNotes from "../ListNotes";
import {
  getBestTimesToCallByLead,
  setBestTimesToCall,
} from "../../services/BestTimeToContact";
import { saveNote } from "../../services/Notes";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../hooks";

const leadOverview = forwardRef((props, ref) => {
  const { dynamoData } = props;
  const [fieldUpdates, setFieldUpdates] = useState({});

  const dispatch = useAppDispatch();
  const { selectedLeadId, bestTimeToCall } = useSelector(
    (state) => state.callcenter
  );

  const [advisorNote, setAdvisorNote] = useState("");
  const [internalNote, setInternalNote] = useState("");
  const [bestTime, setBestTime] = useState(null);
  const [selectedBestTimesToCall, setSelectedBestTimesToCall] = useState([]);

  useImperativeHandle(ref, () => ({
    clearEdits() {
      setFieldUpdates({});
    },
  }));

  useEffect(() => {
    if (bestTime?.results?.length > 0) {
      setSelectedBestTimesToCall(
        bestTime?.results[0]?.call_time.map((id) => "" + id)
      );
    }
  }, [bestTime]);

  const grabBestTimes = () => {
    getBestTimesToCallByLead(selectedLeadId).then((res) => {
      setBestTime(res);
    });
  };

  useEffect(() => {
    if (selectedLeadId) {
      grabBestTimes();
    }
  }, []);

  const advisorNotes = useRef();
  const internalNotes = useRef();

  const sendNote = (type) => {
    if (type === "internal") {
      saveNote(
        {
          lead: selectedLeadId,
          note: internalNote,
        },
        "internal"
      )
        .then(() => {
          internalNotes.current.refreshNotes();
          setInternalNote("");
          toast.success("Note saved successfully");
        })
        .catch(() => {
          alert("Oops! There seems to be an error with your note.");
        });
    } else {
      saveNote({
        lead: selectedLeadId,
        note: advisorNote,
      })
        .then(() => {
          advisorNotes.current.refreshNotes();
          setAdvisorNote("");
          toast.success("Note saved successfully");
        })
        .catch(() => {
          alert("Oops! There seems to be an error with your note.");
        });
    }
  };

  useEffect(() => {
    if (!fieldUpdates) return;
    dispatch(setLeadUpdates(fieldUpdates));
  }, [fieldUpdates]);

  return (
    <form ref={ref}>
      <ul className={"overview-list"}>
        {OverviewFields(dynamoData).map((field) => {
          return (
            <li key={field.name}>
              <EditableInput
                label={field.name}
                onChange={(update) => {
                  if (!field?.inputProps?.disabled && field.path) {
                    setFieldUpdates({
                      ...fieldUpdates,
                      [field.path]: update,
                    });
                  }
                }}
                {...field}
              >
                {field.value()}
              </EditableInput>
            </li>
          );
        })}
      </ul>
      <div className="spacer"></div>
      <div className="notes-form-item">
        <label htmlFor="">Advisor notes</label>
        <textarea
          rows={5}
          placeholder={"Add a note here"}
          value={advisorNote}
          onChange={(e) => {
            setAdvisorNote(e.target.value);
          }}
        />
      </div>
      <button
        className={"button smallest close background-green text-color-white"}
        onClick={(e) => {
          e.preventDefault();
          sendNote("advisor");
        }}
      >
        Add Advisor Note
      </button>
      <ListNotes key={"advisor-notes"} ref={advisorNotes} />
      <div className="spacer"></div>
      <div className="notes-form-item">
        <label htmlFor="">Internal notes</label>
        <textarea
          rows={5}
          placeholder={"Add a note here"}
          value={internalNote}
          onChange={(e) => {
            setInternalNote(e.target.value);
          }}
        />
      </div>
      <button
        className={"button smallest close background-green text-color-white"}
        onClick={(e) => {
          e.preventDefault();
          sendNote("internal");
        }}
      >
        Add Internal Note
      </button>
      <ListNotes key={"internal-notes"} ref={internalNotes} type={"internal"} />
      <div className="spacer"></div>
      <label htmlFor="">Best time to call</label>
      {bestTime?.results?.length > 0 &&
        bestTime?.results.map((time) => {
          return (
            <div key={time?.id} className={"badge"}>
              {time?.time}
            </div>
          );
        })}
      <ul className="options">
        {Object.keys(bestTimeToCall).map((id) => {
          const time = {
            id,
            time: bestTimeToCall[id],
          };

          return (
            <li
              key={id}
              onClick={() => {
                const currentlySelectedTimes = new Set(selectedBestTimesToCall);
                if (currentlySelectedTimes.has(id)) {
                  currentlySelectedTimes.delete(id);
                } else {
                  currentlySelectedTimes.add(id);
                }
                setSelectedBestTimesToCall(Array.from(currentlySelectedTimes));
              }}
            >
              <input
                type="radio"
                className="custom-checkbox"
                name={id}
                key={id}
                checked={selectedBestTimesToCall.includes(id)}
                onChange={() => {}}
              />
              <label htmlFor={id}>{time?.time}</label>
            </li>
          );
        })}
      </ul>
      <div className="spacer"></div>
      <button
        className={"button smallest close background-green text-color-white"}
        disabled={selectedBestTimesToCall.length === 0}
        onClick={(e) => {
          e.preventDefault();
          setBestTimesToCall(
            {
              lead: selectedLeadId,
              call_time: selectedBestTimesToCall,
            },
            bestTime?.results?.length > 0,
            bestTime?.results[0]?.id ?? null
          )
            .then(() => {
              grabBestTimes();
            })
            .then(() => {
              toast.success("Successfully updated best times to call");
            })
            .catch(() => {
              alert(
                "Oops! We have failed to update this value, please try again later"
              );
            });
        }}
      >
        Save Best Time{selectedBestTimesToCall.length > 1 && "s"}
      </button>
    </form>
  );
});

export default leadOverview;
