import React, { useState } from "react";

import "./styles.scss";
import { logLeadEngagement, setScheduledCall } from "../../services/Leads";
import { toast } from "react-toastify";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import moment from "moment";
import Button from "../Button";
import { useAppSelector } from "../../hooks";

const RemoveLead = React.forwardRef((props, ref) => {
  const [delayDate, setDelayDate] = useState();
  const { selectedLeadId } = useAppSelector((state) => state.callcenter);
  const [isDelay, setIsDelay] = useState(false);

  const onDelayLead = () => {
    setIsDelay(true);
    const data = {
      scheduled_at: moment(delayDate).format("YYYY-MM-DD HH:mm"),
      lead: selectedLeadId,
    };
    setScheduledCall(data)
      .then(() => {
        logLeadEngagement({
          type: "call_disposition",
          action: "callback_requested",
          lead: selectedLeadId,
          log: "Callback requested",
        })
          .then(() => {
            setIsDelay(true);
            window.location.reload();
          })
          .catch((err) => {
            toast.error("Error while logging lead engagement");
          });
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <div ref={ref}>
      <div className="user-info">
        <p className="h3 margin-none">Select date</p>
      </div>
      <p className="margin-none">
        Use the datepicker below to select a date that this lead will reappear
        on the Marketplace
      </p>
      <div className="spacer"></div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDateTimePicker
          className={"margin-right"}
          renderInput={(props) => <TextField {...props} />}
          label="Select callback time and date"
          value={delayDate}
          ampm={false}
          disablePast
          onChange={(newValue) => {
            setDelayDate(newValue);
          }}
        />
      </LocalizationProvider>
      <div className="spacer"></div>
      <div className="lead-actions">
        <Button
          title={"Cancel"}
          onClick={() => {
            props.closeModal();
          }}
          colour={"background-dark-grey"}
          textColor={"text-color-white"}
          isLoaded={true}
        />
        <Button
          onClick={onDelayLead}
          isLoaded={!isDelay}
          title={"Delay"}
          colour={"background-green"}
          textColor={"text-color-white"}
        />
      </div>
    </div>
  );
});

export default RemoveLead;
