import React, { useState } from "react";
import "./styles.scss";

const EditableInput = (props) => {
  const [value, setValue] = useState(props?.value() || "");

  if (props?.inputProps?.as === "select") {
    return (
      <div className={"form-item"}>
        <label>{props?.label}</label>
        <select
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            props.onChange(e.target.value);
          }}
          {...props?.inputProps}
        >
          {Object.keys(props.inputProps.options).map((value, index) => {
            return (
              <option key={index} value={value}>
                {props.inputProps.options[value]}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  return (
    <div className={"form-item"}>
      <label>{props?.label}</label>
      {props?.inputProps?.as === "textarea" ? (
        <textarea
          type={"text"}
          onChange={(e) => {
            setValue(e.target.value);
            props.onChange(e.target.value);
          }}
          {...props?.inputProps}
          value={value}
        />
      ) : (
        <input
          type={"text"}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            props.onChange(e.target.value);
          }}
          {...props?.inputProps}
        />
      )}
    </div>
  );
};
export default EditableInput;
