import React, { useEffect, useState } from "react";
import Table, { SortOrder, TableProps } from "react-data-table-component";
import { ILeadInterface } from "../../interfaces/ILead.interface";
import LoadingSpinner from "../LoadingSpinner";
import { IPaginationResponse } from "../../interfaces/IPaginationResponse.interface";
import { INotificationFilters } from "../../interfaces/NotificationFilters.interface";
import { setLeads } from "../../redux/marketplace";
import { useAppDispatch } from "../../hooks";

interface Props extends TableProps<any> {
  dataRequest: (
    page: number,
    pageSize: number,
    filters?: INotificationFilters | null,
    sortColumn?: string,
    sortOrder?: "asc" | "desc" | null
  ) => Promise<any>;
  filters?: INotificationFilters | null;
}

const removeEmpty = (obj: any) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key]))
      (newObj as any)[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) (newObj as any)[key] = obj[key];
  });
  return newObj;
};

const PaginatedTable = (props: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [paginationRowsPerPage, setPaginationRowsPerPage] = useState(15);
  const [paginationPage, setPaginationPage] = useState(1);
  const [sortByColumnName, setSortByColumnName] = useState<string | null>(null);
  const [sortByDirection, setSortByDirection] = useState<string | null>(null);
  const [requestData, setRequestData] =
    useState<IPaginationResponse<ILeadInterface> | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoaded(false);
    props
      .dataRequest(
        paginationPage,
        paginationRowsPerPage,
        removeEmpty(props.filters) as any,
        sortByColumnName as any,
        sortByDirection as SortOrder
      )
      .then((data) => {
        setRequestData(data);
        dispatch(setLeads(data?.results));
        setIsLoaded(true);
      });
  }, [
    paginationPage,
    paginationRowsPerPage,
    props.filters,
    sortByColumnName,
    sortByDirection,
  ]);

  const setDefaultExpanded = (data: IPaginationResponse<ILeadInterface>) => {
    if (!data?.results) {
      return requestData;
    }
    const firstAvailableLead = data.results.find(
      (lead: ILeadInterface) => lead.lead_status === "Available"
    );
    return {
      ...data,
      results: data.results.map((lead: ILeadInterface) => {
        if (firstAvailableLead && lead.lead_id === firstAvailableLead.lead_id) {
          return {
            ...lead,
            defaultExpanded: true,
          };
        }
        return lead;
      }),
    };
  };

  const onSort = (column: any, sortDirection: SortOrder) => {
    setSortByColumnName(column.id);
    setSortByDirection(sortDirection);
  };

  if (!requestData) return null;

  return (
    <Table
      {...props}
      data={setDefaultExpanded(requestData)?.results ?? []}
      progressPending={!isLoaded}
      progressComponent={<LoadingSpinner text={"Fetching leads"} />}
      paginationTotalRows={requestData?.count}
      paginationServer
      pagination
      expandableIcon={{
        collapsed: (
          <svg
            fill="currentColor"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
            <path d="M0-.25h24v24H0z" fill="none"></path>
          </svg>
        ),
        expanded: (
          <svg
            fill="currentColor"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              color={"white"}
              d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"
            ></path>
            <path d="M0-.75h24v24H0z" fill="none"></path>
          </svg>
        ),
      }}
      paginationPerPage={15}
      paginationRowsPerPageOptions={[15, 25, 50, 100]}
      onChangeRowsPerPage={(currentRowsPerPage) => {
        setPaginationRowsPerPage(currentRowsPerPage);
      }}
      customStyles={{
        headRow: {
          style: {
            borderBottom: "none",
            minHeight: "auto",
          },
        },
        headCells: {
          style: {
            backgroundColor: "#F3F6F9",
            borderRight: "1px solid #D6E0EA",
            borderBottom: "2px solid #E4EBF1",
            fontSize: 12,
            fontWeight: 700,
            padding: 10,
            color: "#081F49",
            whiteSpace: "pre-line",
          },
        },
        cells: {
          style: {
            borderRight: "1px solid #D6E0EA",
            borderBottom: "1px solid #D6E0EA",
            color: "#081F49",
          },
        },
        rows: {
          style: {
            borderBottom: "none",
          },
        },
        pagination: {
          style: {
            borderTop: "none",
          },
        },
        responsiveWrapper: {
          style: {
            borderRadius: 0,
          },
        },
        expanderButton: {
          style: {
            backgroundColor: "#09BC8A",
            borderRadius: 0,
          },
        },
      }}
      onChangePage={(page) => {
        setPaginationPage(page);
      }}
      onSort={onSort}
    />
  );
};

export default PaginatedTable;
