import React, { useEffect, useState } from "react";
import { getUsersByCompanies } from "../../services/Users";
import {
  IAdvisor,
  IManageUsersInterface,
} from "../../interfaces/IManageUsers.interface";
import { TableColumn } from "react-data-table-component";
import Table from "../../components/Table";
import Module from "../../components/Module";
import PipelineStats from "../../components/PipelineStats";
import Button from "../../components/Button";
import { useModal } from "react-modal-hook";
import Modal from "../../components/Modal";
import AddUserForm from "../../components/AddUserForm";
import moment from "moment";
import Search from "../../components/Search";
import { useAppSelector } from "../../hooks";

const ManageUsersColumns: TableColumn<IAdvisor>[] = [
  {
    name: "Advisor Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Created",
    selector: (row) => row.name,
    format: (row) => moment(row.created_at).format("llll"),
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Telephone",
    selector: (row) => row.phonenumber,
    sortable: true,
  },
  {
    name: "Conversion %",
    selector: (row) => row.conversion_percentage,
    format: (row) => row.conversion_percentage + "%",
    sortable: true,
    width: "150px",
  },
  {
    name: "Total Sales",
    selector: (row) => row.total_sales,
    sortable: true,
    width: "150px",
  },
  {
    name: "Pipeline",
    selector: (row) => row.id,
    format: (row) => <PipelineStats advisor_id={row.id} {...row} />,
    width: "400px",
  },
];

const ManageUsersPage = () => {
  const [company, setCompany] = useState<IManageUsersInterface>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { user } = useAppSelector((state: any) => state.user);
  useEffect(() => {
    getUsersByCompanies(user.company_id).then((r: IManageUsersInterface) =>
      setCompany(r)
    );
  }, []);

  const [showModal, hideModal] = useModal(() => (
    <Modal onClose={hideModal} isSmall>
      <AddUserForm />
    </Modal>
  ));

  const searchedData = company?.advisors.filter((advisor) => {
    return (
      advisor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      advisor.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      advisor.phonenumber.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <Module
      title={
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              {company?.company_name
                ? "Advisors within " + company.company_name
                : "Current Users"}
            </div>
            <div>
              <Button
                colour={"background-green"}
                title={"Add Advisor"}
                textColor={"text-color-white"}
                onClick={() => {
                  showModal();
                }}
              />
            </div>
          </div>
        </>
      }
      body={
        <>
          <Search
            onSearch={(value: string) => {
              setSearchTerm(value);
            }}
          />
        </>
      }
    >
      <Table
        data={searchedData}
        columns={ManageUsersColumns}
        progressPending={false}
      />
    </Module>
  );
};

export default ManageUsersPage;
