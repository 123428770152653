import { useEffect, useState } from "react";
import {
  getLeadsStats,
  getLogsInDateRange,
  searchLeadsByCompany,
} from "../services/Leads";
import { Moment } from "moment";
import { useAppSelector } from "./index";

const leadLogActions = {
  no_answer: "No Answer",
  not_interested: "Not Interested",
  qualified: "Qualified",
  callback_requested: "Callback Requested",
  appointment_booked: "Appointment Booked",
};

const leadStatusOptions = {
  "Sold & Qualified": "Sold & Qualified",
  "Sold & Unqualified": "Sold & Unqualified",
  "Callback Requested": "Callback Requested",
  Qualified: "Qualified",
  Unqualified: "Unqualified",
  Removed: "Removed",
  "Not Sold": "Not Sold",
};

type KPISettings = {
  start: Moment;
  end: Moment;
};

const getUsersFromLogs = (logs: Array<any>) => {
  return logs
    .map((log) => log?.user)
    .filter((user, index, self) => self.indexOf(user) === index);
};

const getLogsForUser = (logs: Array<any>, user: string) => {
  return logs.filter((log) => log?.user === user);
};

const getLogsForAction = (logs: Array<any>, action: string) => {
  return logs.filter((log) => log?.action === action);
};

export const useKpiCharts = (props: KPISettings) => {
  const { start, end } = props;
  const { user } = useAppSelector((state: any) => state.user);
  const [isLoaded, setIsLoaded] = useState(false);
  const [leadStatuses, setLeadStatuses] = useState<any[]>([]);
  const [logs, setLogs] = useState<Array<any>>([]);

  let logsByAction: any[] = [];
  let logsByUser: any[] = [];
  let totalLeadsPurchasedByCompany: number = 0;
  const totalLeadsGenerated = leadStatuses?.length;
  const totalDispositions = logs?.length;
  let statusByGeneratedLeadForUKMC: any[] = [];
  let statusByGeneratedLeadForGM: any[] = [];

  const searchForBoughtLeadsByCompanyId = () => {
    searchLeadsByCompany({
      company_id: user?.company_id,
      sold_at_after: start.format("YYYY-MM-DD HH:mm"),
      sold_at_before: end.format("YYYY-MM-DD HH:mm"),
    }).then((res) => {
      totalLeadsPurchasedByCompany = res?.count;
    });
  };

  useEffect(() => {
    setIsLoaded(false);
    getLogsInDateRange(
      start.format("YYYY-MM-DD-HH:mm"),
      end.format("YYYY-MM-DD-HH:mm")
    ).then((res) => {
      setLogs(res);
      searchForBoughtLeadsByCompanyId();
      getLeadsStats(
        start.format("YYYY-MM-DD-HH:mm"),
        end.format("YYYY-MM-DD-HH:mm")
      )
        .then((res) => {
          setLeadStatuses(res);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    });
  }, [start, end]);

  if (!isLoaded) {
    return [
      isLoaded,
      logsByUser,
      logsByAction,
      totalLeadsGenerated,
      totalLeadsPurchasedByCompany,
      totalDispositions,
      statusByGeneratedLeadForUKMC,
      statusByGeneratedLeadForGM,
    ];
  }

  const users = getUsersFromLogs(logs);

  Object.keys(leadLogActions).map((action) => {
    const logCount = getLogsForAction(logs, action).length;
    if (logCount > 0) {
      logsByAction.push({
        type: (leadLogActions as any)[action],
        value: logCount,
      });
    }
  });

  Object.keys(leadStatusOptions).map((status) => {
    const statusCountGM = leadStatuses.filter(
      (lead) => lead.lead_status_gm === status
    ).length;

    const statusCountUKMC = leadStatuses.filter(
      (lead) => lead.lead_status_call_center === status
    ).length;

    if (statusCountGM > 0) {
      statusByGeneratedLeadForGM.push({
        type: (leadStatusOptions as any)[status],
        value: statusCountGM,
      });
    }

    if (statusCountUKMC > 0) {
      statusByGeneratedLeadForUKMC.push({
        type: (leadStatusOptions as any)[status],
        value: statusCountUKMC,
      });
    }
  });

  users.forEach((user) => {
    const logsForUser = getLogsForUser(logs, user);
    const logsPerAction: { name: any; value: number; type: any }[] = [];
    Object.keys(leadLogActions).forEach((action) => {
      const friendlyAction = (leadLogActions as any)[action as keyof any];
      const logsForAction = getLogsForAction(logsForUser, action);

      if (logsForAction.length > 0) {
        logsPerAction.push({
          name: user,
          value: logsForAction.length,
          type: friendlyAction,
        });
      }
    });
    logsByUser = [...logsByUser, ...logsPerAction];
  });

  return [
    isLoaded,
    logsByUser,
    logsByAction,
    totalLeadsGenerated,
    totalLeadsPurchasedByCompany,
    totalDispositions,
    statusByGeneratedLeadForUKMC,
    statusByGeneratedLeadForGM,
  ];
};

export default useKpiCharts;
