import {
  getCallbackLeads,
  GetCallList,
  getQualifiedLeads,
  getRemovedLeads,
  getSoldLeads,
} from "../services/Leads";
import { ILeadInterface } from "../interfaces/ILead.interface";
import moment from "moment/moment";
import {
  setCallbackData,
  setLeadData,
  setQualifiedLeads,
  setRemovedLeads,
  setSoldLeads,
} from "../redux/callcenter";

export default [
  {
    id: "call_list",
    title: "Call List",
    icon: "call_list",
    stateFunc: setLeadData,
    requestFunc: GetCallList,
    filter: (data: ILeadInterface[]) => {
      return data.filter((lead: ILeadInterface) => {
        if (lead.callback_schedule_for === "") {
          return lead;
        } else if (
          !(
            moment(lead?.callback_schedule_for).subtract(15, "minutes") >
            moment()
          )
        ) {
          return lead;
        } else if (lead?.lead_status != "Callback Requested") {
          return lead;
        } else return [];
      });
    },
  },
  {
    id: "callback_requested",
    title: "Callback Requested",
    icon: "callback_requested",
    stateFunc: setCallbackData,
    requestFunc: getCallbackLeads,
    filter: (data: ILeadInterface[]) => {
      return data;
    },
  },
  {
    id: "qualified_leads",
    title: "Qualified Leads",
    icon: "qualified_leads",
    stateFunc: setQualifiedLeads,
    requestFunc: getQualifiedLeads,
    filter: (data: ILeadInterface[]) => {
      return data;
    },
  },
  {
    id: "sold_leads",
    title: "Sold Leads",
    icon: "sold_leads",
    stateFunc: setSoldLeads,
    requestFunc: getSoldLeads,
    filter: (data: ILeadInterface[]) => {
      return data;
    },
  },
  {
    id: "removed_leads",
    title: "Removed Leads",
    icon: "removed_leads",
    stateFunc: setRemovedLeads,
    requestFunc: getRemovedLeads,
    filter: (data: ILeadInterface[]) => {
      return data;
    },
  },
];
