import React, { useEffect, useState } from "react";

import "./styles.scss";

import Module from "../../components/Module";
import { useParams } from "react-router-dom";
import Index from "../../components/LeadDetails";
import classNames from "classnames";
import {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import { getNotificationById } from "../../services/Notifications";
import LeadFilters from "../../components/LeadFilters";
import { useModal } from "react-modal-hook";
import Modal from "../../components/Modal";
import BuyLead from "../../components/BuyLead";
import { useDispatch } from "react-redux";
import { setSelectedLeadId } from "../../redux/marketplace";
import { TableByFilters } from "../../components/PaginatedTable";
import {
  getMarketplaceLeads,
  getSingleMarketplaceLead,
} from "../../services/Leads";
import { INotificationFilters } from "../../interfaces/NotificationFilters.interface";
import Table from "../../components/Table";
import DisplayErrorMessageModal from "../../components/DisplayErrorMessageModal";

const MarketplacePage = () => {
  const [filters, setFilters] = useState({});

  const dispatch = useDispatch();

  const [notificationModel, setNotificationModel] = useState<any>(null);
  const [singleLead, setSingleLead] = useState<any[]>([]);
  const [hasError, setHasError] = useState(false);

  let { lead, notification } = useParams();

  useEffect(() => {
    if (notification) {
      getNotificationById(notification).then((res) => {
        setNotificationModel(res);
      });
    }
    if (lead) {
      getSingleMarketplaceLead(lead).then((res) => {
        setSingleLead([
          {
            ...res,
            defaultExpanded: true,
          },
        ]);
        if (res?.detail) {
          setHasError(true);
        }
      });
    }
  }, []);

  const [showModal, hideModal] = useModal(() => (
    <Modal onClose={hideModal} isSmall>
      <BuyLead onClose={hideModal} />
    </Modal>
  ));

  const columns = [
    {
      name: "Mortgage value",
      selector: (row: any) => {
        return `£${row.mortgage_value}`;
      },
      sortable: false,
      allowOverflow: false,
    },
    {
      name: "LTV",
      width: "80px",
      selector: (row: any) => {
        return row.ltv + "%";
      },
      sortable: false,
    },
    {
      name: "Mortgage type",
      selector: (row: any) => row.mortgage_type,
      sortable: false,
    },
    {
      name: "Purpose",
      selector: (row: any) => row.mortgage_purpose,
      sortable: false,
    },
    {
      name: "Location",
      selector: (row: any) => row.location,
      sortable: false,
    },
    {
      name: "Credit status",
      selector: (row: any) => {
        switch (row.credit_status) {
          case "Bad":
            return <span className="text-color-red">{row.credit_status}</span>;
          case "Poor":
            return <span className="text-color-red">{row.credit_status}</span>;
          case "Fair":
            return (
              <span className="text-color-yellow">{row.credit_status}</span>
            );
          case "Good":
            return (
              <span className="text-color-green">{row.credit_status}</span>
            );
          case "Excellent":
            return (
              <span className="text-color-green">{row.credit_status}</span>
            );
          default:
            return row.credit_status;
        }
      },
      sortable: false,
    },
    {
      name: "Qualified",
      selector: (row: any) => {
        return (
          <span
            className={row.qualified ? "text-color-green" : "text-color-red"}
          >
            {row.qualified ? "Yes" : "No"}
          </span>
        );
      },
      sortable: true,
      id: "qualified",
    },
    {
      name: "Status",
      selector: (row: any) => {
        switch (row.lead_status) {
          case "Available":
            return (
              <span className="status background-green">{row.lead_status}</span>
            );
          case "Sold":
            return (
              <span className="status background-red">{row.lead_status}</span>
            );
          case "Calling":
            return (
              <span className="status background-blue animation-flicker">
                {row.lead_status}
              </span>
            );
          case "Selling":
            return (
              <span className="status background-yellow animation-flicker">
                {row.lead_status}
              </span>
            );
          case "Incoming":
            return (
              <span className="status background-dark-grey animation-flicker">
                {row.lead_status}
              </span>
            );
          default:
            return row.lead_status;
        }
      },
      sortable: false,
    },
    {
      name: "Generated/Qualified",
      selector: (row: any) => {
        if (row.qualified_at) {
          return (
            <span className={"text-color-green"}>
              {row?.qualified_at_friendly}
            </span>
          );
        } else {
          return row.generated_friendly;
        }
      },
      sortable: true,
      id: "created_at",
    },
    {
      name: "Price",
      width: "130px",
      selector: (row: any) => {
        if (row.lead_status === "Sold") {
          return (
            <span>
              <i
                className={classNames("fa-solid fa-coins text-color-yellow")}
              ></i>
              &nbsp;
              {row.price}
            </span>
          );
        }
        return (
          <>
            <span
              className={classNames({
                "discounted-price": row?.discounted_price,
              })}
            >
              <i
                className={classNames("fa-solid fa-coins", {
                  "text-color-red": row?.discounted_price,
                  "text-color-yellow": !row?.discounted_price,
                })}
              ></i>
              &nbsp;
              {row.price}
            </span>
            {row?.discounted_price && (
              <span>
                <i className="fa-solid fa-coins text-color-yellow"></i>&nbsp;
                {row?.discounted_price}
              </span>
            )}
          </>
        );
      },
      sortable: false,
    },
    {
      name: "",
      style: {
        justifyContent: "center",
      },
      selector: (row: any) => {
        if (row.lead_status == "Available" || row.lead_status == "Calling") {
          return (
            <span
              className="button table-button buy_lead background-blue full"
              data-leadid={row.lead_id}
              onClick={() => {
                dispatch(setSelectedLeadId(row.lead_id));
                showModal();
              }}
            >
              Buy
            </span>
          );
        } else return true;
      },
    },
  ] as TableColumn<any>[];

  const onFilter = (values: any) => {
    setFilters({
      ...filters,
      ...values,
    });
  };

  const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({
    data,
  }) => {
    return <Index leadId={data?.lead_id} />;
  };

  const [showError, hideError] = useModal(() => (
    <DisplayErrorMessageModal type={"lead_already_sold"} />
  ));

  useEffect(() => {
    if (hasError) {
      showError();
    } else {
      hideError();
    }
  }, [hasError]);
  return (
    <>
      <Module
        title={"Leads available for purchase"}
        body={
          <LeadFilters onChange={onFilter} notification={notificationModel} />
        }
      >
        <>
          {lead ? (
            <Table
              columns={columns}
              data={singleLead}
              expandableRowsComponent={ExpandedComponent}
              expandableRows={true}
              expandableRowDisabled={(row: any) =>
                row.lead_status === "Sold" ? true : false
              }
              progressPending={!(singleLead.length > 0)}
            />
          ) : (
            <TableByFilters
              data={[]}
              filters={filters as INotificationFilters}
              columns={columns}
              dataRequest={getMarketplaceLeads}
              expandableRowsComponent={ExpandedComponent}
              expandableRows={true}
              expandOnRowClicked={true}
              expandableRowExpanded={(row: any) => row.defaultExpanded}
              expandableRowDisabled={(row: any) =>
                row.lead_status === "Sold" ? true : false
              }
            />
          )}
        </>
      </Module>
    </>
  );
};

export default MarketplacePage;
