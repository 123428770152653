import React from "react";
import classNames from "classnames";

export type FieldProps = {
  as: "input" | "textarea" | "select" | "radio";
  name: string;
  label: string;
  value?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  className?: string;
  type?: string;
  options?: string[];
  error?: string;
  inputOnly?: boolean;
  onChange?: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
};

const Field = (props: FieldProps) => {
  const renderField = (props: FieldProps) => {
    switch (props.as) {
      case "input":
        return (
          <input
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            required={props.required}
            onChange={props?.onChange}
            className={classNames(props?.className, {
              "field-error": props.error,
            })}
          />
        );
      case "textarea":
        return (
          <textarea
            name={props.name}
            placeholder={props.placeholder}
            required={props.required}
            onChange={props?.onChange}
            className={classNames(props?.className, {
              "field-error": props.error,
            })}
          />
        );
      case "select":
        return (
          <select
            name={props.name}
            required={props.required}
            onChange={props?.onChange}
            className={classNames(props?.className, {
              "field-error": props.error,
            })}
          >
            {props.options?.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "radio":
        return (
          <>
            <input
              name={props.name}
              type={props.type ?? "radio"}
              checked={props.value === props.defaultValue}
              placeholder={props.placeholder}
              required={props.required}
              className={classNames(props?.className, {
                "field-error": props.error,
              })}
            />
            <label
              className={"h6 margin-quarter"}
              onClick={() => {
                if (!props.onChange) return;
                props.onChange({
                  target: {
                    name: props.name,
                    value: props.value,
                  },
                } as any);
              }}
            >
              {props.label}
            </label>
          </>
        );
      default:
        return null;
    }
  };

  if (props?.inputOnly) {
    return renderField(props);
  }

  return (
    <div
      className={classNames("field", {
        required: props.required,
        error: props.error,
      })}
    >
      <label className={"h6 margin-quarter"}>{props.label}</label>
      {renderField(props)}
      {props?.error && (
        <span className={"error"} style={{ display: "inline-block" }}>
          {props?.error}
        </span>
      )}
    </div>
  );
};

export const FieldLabel = (props: { label: string }) => {
  return <label className={"h6 margin-quarter"}>{props.label}</label>;
};

export const FieldWrapper = (props: { children: React.ReactNode }) => {
  return <div className={"field"}>{props.children}</div>;
};

export default Field;
