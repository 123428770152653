import MarketplaceFilters from "./constants/marketplaceFilters";
import ReactTooltip from "react-tooltip";
import React from "react";

type ModalPropertiesBuyLead = {
  lead_id: string;
  mortgage_value: string;
  mortgage_type: string;
  mortgage_purpose: string;
  price: string;
  discounted_price: string;
};

type ModalCreateNotification = {
  filters: any;
};

export const OpenModal = (props: ModalPropertiesBuyLead) => {
  const {
    lead_id,
    mortgage_value,
    mortgage_type,
    mortgage_purpose,
    price,
    discounted_price,
  } = props;
  console.info(`Purchasing lead ${lead_id}`);

  const priceWrapper = document.getElementById(
    "purchase_modal_lead_cost_wrapper"
  );

  if (discounted_price) {
    // @ts-ignore
    document.getElementById("purchase_modal_lead_cost_discounted").innerHTML =
      discounted_price;
    // @ts-ignore
    document.getElementById("purchase_modal_discounted_price").style.display =
      "block";

    priceWrapper?.classList.add("purchase_modal_lead_cost_wrapper_discounted");
  }

  // @ts-ignore
  document.getElementById("purchase_modal_mortgage_value").innerHTML =
    mortgage_value;
  // @ts-ignore
  document.getElementById("purchase_modal_mortgage_type").innerHTML =
    mortgage_type;
  // @ts-ignore
  document.getElementById("purchase_modal_purpose").innerHTML =
    mortgage_purpose;
  // @ts-ignore
  document.getElementById("purchase_modal_lead_cost").innerHTML = price;
  (
    document.getElementById("purchase_modal_lead_id") as HTMLInputElement
  ).value = lead_id;
  // @ts-ignore
  document.getElementById("lead_purchase_confirmation_modal").style.display =
    "block";
};

export const CreateNotification = ({ filters }: ModalCreateNotification) => {
  const getFilterData = (key: string) => {
    return MarketplaceFilters.filter((filter: any) => filter.id === key)[0];
  };

  const filterListEl = document.getElementById(
    "notification-filter-list"
  ) as HTMLUListElement;

  // Clear filter list before adding it all in
  filterListEl.innerHTML = "";

  const filterListTxtEl = document.getElementById(
    "notification-filters"
  ) as HTMLInputElement;

  let filterListArr: Array<string> = [];

  Object.keys(filters).forEach((id: string) => {
    const filterInfo = MarketplaceFilters.filter(
      (filter: any) => filter.id === id
    )[0];
    filterListEl.innerHTML =
      filterListEl.innerHTML +
      `<li><strong>${filterInfo.name}: </strong>${
        (filterInfo.options as any)[filters[id as keyof object]]
      }</li>`;
    filterListArr.push(filters[id as keyof object]);
    // filterListTxtEl.value = filterListTxtEl.value + filters[id as keyof object] + ',';
  });

  filterListTxtEl.value = filterListArr.join(",");

  if (filterListTxtEl.value != "") {
    document.getElementById("create_notification")?.removeAttribute("disabled");

    if (document.getElementById("cant_create_notification_error") != null) {
      // @ts-ignore
      document.getElementById("cant_create_notification_error").style.display =
          "none";
    }
  }

  // @ts-ignore
  document.getElementById("notification_create_modal").style.display = "block";

  // Remove trailing comma
  filterListTxtEl.innerText = filterListTxtEl.innerText.slice(0, -1);

  const createButton = document.getElementById("create_notification");

  createButton?.addEventListener(
    "click",
    function (event) {
      event.preventDefault();
      event.stopPropagation();

      let data = new FormData();

      // add element values to variables
      const name = (
        document.querySelector(
          'input[name="notification_name"]'
        ) as HTMLInputElement
      ).value;
      const frequency = (
        document.querySelector(
          'select[name="notification_frequency"]'
        ) as HTMLSelectElement
      ).value;

      if (!name || !frequency) {
        alert("Please fill out all fields");
        return;
      }

      data.append(
        "filters",
        (document.getElementById("notification-filters") as HTMLInputElement)
          .value
      );
      data.append(
        "notification_name",
        (
          document.querySelector(
            'input[name="notification_name"]'
          ) as HTMLInputElement
        ).value
      );
      data.append(
        "notification_frequency",
        (
          document.querySelector(
            'select[name="notification_frequency"]'
          ) as HTMLSelectElement
        ).value
      );
      data.append("notification_method", null as any);

      fetch("marketplace/create_notification", {
        method: "POST",
        body: data,
      }).then(() => {
        window.location.replace("/notifications");
      });
    },
    false
  );
};

export const getCallcenterLeadStatus = (
  leadStatus: string,
  lead_id: string
) => {
  switch (leadStatus) {
    case "Available":
      return (
        <span className="status background-green">Qualification Required</span>
      );
    case "Qualified":
      return <span className="status background-green">Qualified</span>;
    case "Sold":
      return <span className="status background-red">{leadStatus}</span>;
    case "Qualifying":
      return (
        <span className="status background-blue animation-flicker">
          {leadStatus}
        </span>
      );
    case "Selling":
      return (
        <span className="status background-yellow animation-flicker">
          {leadStatus}
        </span>
      );
    case "Incoming":
      return (
        <span className="status background-dark-grey animation-flicker">
          {leadStatus}
        </span>
      );
    case "Callback Requested":
      return (
        <span className="status background-dark-blue">Call Back Requested</span>
      );
    case "No Answer":
      return <span className="status background-red">{leadStatus}</span>;
    case "Appointment Booked":
      return <span className="status background-yellow">{leadStatus}</span>;
    case "Not Interested":
      return <span className="status background-red">{leadStatus}</span>;
    default:
      return (
        <span data-tip={lead_id + "-status-tip"}>
          <span
            className={"status background-yellow animation-flicker"}
            data-tip={true}
            data-for={lead_id}
          >
            Opened
          </span>
          <ReactTooltip
            id={lead_id}
            type="dark"
            effect="solid"
            className="tooltip"
          >
            {leadStatus}
          </ReactTooltip>
        </span>
      );
  }
};

// Sends the message that the lead is currently opened by someone
export const OpenLead = (socket: any, lead: any, name: string) => {
  let leadData = {
    ...lead,
    lead_status: `Opened by ${name}`,
  };
  const action = {
    TYPE: "UPDATE_LEAD",
    DATA: leadData,
  };
  socket.send(JSON.stringify(action));
};

export const CloseLead = (socket: any, lead: any) => {
  const action = {
    TYPE: "UPDATE_LEAD",
    DATA: lead,
  };
  socket.send(JSON.stringify(action));
  window.location.reload();
};

export const resolveFromFields = (
  path: string,
  obj: any = self,
  separator = "."
) => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return (
    // @ts-ignore
    properties?.reduce((prev: any, curr: any) => prev && prev[curr], obj) || null
  );
};

export const checkPermission = (
  userPermissions: string[],
  inputPermission: string | string[]
) => {
  if (typeof inputPermission === "object") {
    if (userPermissions.some((r: any) => inputPermission.includes(r))) {
      return true;
    } else return;
  } else if (typeof inputPermission === "string") {
    if (userPermissions.includes(inputPermission)) {
      return true;
    } else return;
  } else {
    return true;
  }
};
