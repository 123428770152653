import React, { useEffect, useImperativeHandle, useState } from "react";
import "./styles.scss";
import LoadingSpinner from "../LoadingSpinner";
import { getNotesByLead } from "../../services/Notes";
import { useAppSelector } from "../../hooks";

type Props = {
  type?: "internal";
};

const ListNotes = React.forwardRef(({ type }: Props, ref) => {
  const [notes, setNotes] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const { selectedLeadId } = useAppSelector((state) => state.callcenter);

  const grabNotes = () => {
    getNotesByLead(selectedLeadId, type)
      .then((res: { results: any }) => setNotes(res?.results))
      .then(() => setLoading(false));
  };

  useImperativeHandle(ref, () => ({
    refreshNotes: () => {
      grabNotes();
    },
  }));

  useEffect(() => {
    grabNotes();
  }, []);

  if (loading) {
    return <LoadingSpinner text={"Loading notes"} />;
  }

  return (
    <div className={"notes-list"} ref={ref as any}>
      <ul id="lead_modal_notes" className="lead_modal_data">
        {notes?.map((note: any, index: number) => {
          let date = new Date(note.created_at);
          return (
            <li key={index}>
              <div className="note">
                <p>{note?.note}</p>
                <p className="text-size-smallest">
                  <strong>— {note?.created_by || note?.user}</strong>
                </p>
              </div>
              <div className="meta">
                <div className="avatar">
                  <span className="initials customer_initials">
                    {note?.created_by
                      ?.split(" ")
                      ?.map((n: any) => n[0])
                      ?.join("")}
                  </span>
                </div>
                <div className="timestamp text-size-smallest text-color-standard">
                  {date.toLocaleString("en-GB", {
                    dateStyle: "long",
                    timeStyle: "short",
                  } as Intl.DateTimeFormatOptions)}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default ListNotes;
