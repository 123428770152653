import { configureStore } from "@reduxjs/toolkit";
import callcenterReducer from "./callcenter";
import userReducer from "./user";
import websocketReducer from "./websocket";
import marketplaceReducer from "./marketplace";

export const store = configureStore({
  reducer: {
    marketplace: marketplaceReducer,
    callcenter: callcenterReducer,
    user: userReducer,
    websocket: websocketReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
