import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedLeadId: null,
  leads: [],
};

const marketplace = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    setSelectedLeadId(state, action) {
      state.selectedLeadId = action.payload;
    },
    setLeads(state, action) {
      state.leads = action.payload;
    },
  },
});

export const { setSelectedLeadId, setLeads } = marketplace.actions;
export default marketplace.reducer;
