import React from "react";

const Search = ({ onSearch }) => {
  return (
    <div className={"search-wrap"}>
      <div className="search">
        <form action="#">
          <div className="field-icon search-icon">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Search;
