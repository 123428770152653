import React from "react";
import { setLeadUpdates } from "../../redux/callcenter";
import { logLeadEngagement } from "../../services/Leads";
import Button from "../Button";
import { useAppDispatch, useAppSelector } from "../../hooks";

const SaveLead = React.forwardRef((props, ref) => {
  const { leadUpdates, selectedLeadId, leads } = useAppSelector(
    (state) => state?.callcenter
  );

  const { user } = useAppSelector((state) => state?.user);
  const dispatch = useAppDispatch();
  const selectedLead = leads.filter((x) => x.lead_id === selectedLeadId)[0];
  const [isSaving, setIsSaving] = React.useState(false);

  const onConfirm = () => {
    setIsSaving(true);
    fetch("/callcenter/leads/" + selectedLead.lead_id, {
      method: "PATCH",
      body: JSON.stringify(leadUpdates),
    })
      .then((x) => x.json())
      .then((x) => {
        dispatch(setLeadUpdates({}));
        logLeadEngagement({
          type: "update",
          action: "update_lead",
          lead: selectedLeadId,
          log: `${user.first_name} ${
            user.last_name
          } updated lead ${selectedLeadId} with ${JSON.stringify(
            leadUpdates
          )} on ${new Date().toLocaleString()}`,
        }).then(() => {
          props.closeAll();
        });
      })
      .catch((err) => alert(err));
  };

  return (
    <div ref={ref}>
      <div className="user-info">
        <p className="h3 margin-none">Are you sure?</p>
        <p>Please check the details below to ensure the updates are correct</p>
      </div>
      <div className="module background-dark-grey">
        <div className="module-heading">
          <div className="basic-list">
            <ul>
              {Object.keys(leadUpdates).map((update) => {
                return (
                  <li>
                    <strong style={{ textTransform: "capitalize" }}>
                      {update
                        .split(".")
                        [update.split(".").length - 1].split("_")
                        .join(" ")}
                      :
                    </strong>{" "}
                    {leadUpdates[update]}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="lead-actions">
        <Button
          onClick={props.closeAll}
          isLoaded={true}
          title={"Cancel"}
          colour={"background-dark-grey"}
          textColor={"text-color-standard"}
        />
        {!props.dataEdits && (
          <Button
            onClick={() => {
              onConfirm();
            }}
            isLoaded={!isSaving}
            title={"Save"}
            colour={"background-green"}
            textColor={"text-color-white"}
          />
        )}
      </div>
    </div>
  );
});

export default SaveLead;
