import axios from "axios";

axios.defaults.baseURL = process.env.API_URL || '';

axios.interceptors.request.use(
  function (config: any) {
    config.headers?.common?.set(
      "X-CSRFToken",
      (
        document.querySelector(
          'input[name="csrfmiddlewaretoken"]'
        ) as HTMLInputElement
      ).value || ""
    );
    config.headers?.common?.setContentType("application/json");
    config.headers?.common?.setAccept("application/json");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axios;
