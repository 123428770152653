import React, { useState } from "react";
import { logLeadEngagement, updateLeadByLead } from "../../services/Leads";
import Button from "../Button";
import { useAppSelector } from "../../hooks";

const RemoveLead = React.forwardRef((props, ref) => {
  const [reason, setReason] = useState();
  const [isRemoving, setIsRemoving] = useState(false);
  const { selectedLeadId } = useAppSelector((state) => state.callcenter);
  const onRemoveLead = () => {
    setIsRemoving(true);
    logLeadEngagement({
      type: "call_disposition",
      action: "not_interested",
      log: reason,
      lead: selectedLeadId,
    }).then(() => {
      updateLeadByLead(selectedLeadId, {
        deleted_at: new Date(),
      }).then(() => {
        window.location.reload();
      });
    });
  };

  return (
    <div ref={ref}>
      <div className="user-info">
        <p className="h3 margin-none">Are you sure?</p>
      </div>
      <div className="spacer"></div>
      <form>
        <label>Please select a reason why this lead is being removed</label>
        <select
          name="reason"
          defaultValue={null}
          onChange={(e) => {
            setReason(e.target.value);
          }}
        >
          <option value={null}>Select a reason</option>
          <option value="Out of Criteria: Low Income">
            Out of Criteria: Low Income
          </option>
          <option value="Out of Criteria: Credit">
            Out of Criteria: Credit
          </option>
          <option value="Client Not Interested">Client Not Interested</option>
          <option value="Wrong Number">Wrong Number</option>
          <option value="Duplicate Lead">Duplicate Lead</option>
          <option value="Fake Lead">Fake Lead</option>
        </select>
      </form>
      <div className="spacer"></div>
      <div className="lead-actions">
        <a
          href="#Cancel"
          onClick={(e) => {
            e.preventDefault();
            props.onClose();
          }}
          className="button smallest close background-blue text-color-white"
        >
          Cancel
        </a>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onRemoveLead();
          }}
          isLoaded={!isRemoving}
          colour={"background-red"}
          textColor={"text-color-white"}
          title={"Remove Lead"}
        />
      </div>
    </div>
  );
});

export default RemoveLead;
