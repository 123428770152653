import React, { useEffect } from "react";
import { getLeadLogsByLead } from "../../services/Leads";
import "./styles.scss";

const LeadLogView = ({ lead_id }: any) => {
  const [leadLogs, setLeadLogs] = React.useState([]);
  useEffect(() => {
    getLeadLogsByLead(lead_id).then((response) => setLeadLogs(response));
  }, []);

  return (
    <div className={"lead-log-view"}>
      <ul>
        {leadLogs.map((item: any) => (
          <li key={item.id}>
            <div className={"lead-log-view-item"}>
              <div className={"lead-log-view-description"}>
                {item.user}: {item.log}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeadLogView;
