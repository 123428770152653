import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import ReactEditeTable, { InputEditor } from "react-edit-table";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import "./styles.scss";
import {
  CloseLead,
  OpenLead,
  resolveFromFields as resolve,
} from "../../helpers";
import { useSocket } from "../../hooks/useSocket";
import classNames from "classnames";
import { useModal } from "react-modal-hook";
import RemoveLead from "../RemoveLead";
import Modal from "../Modal";
import SaveLead from "../SaveLead";
import { clearLeadUpdates, setLeadUpdates } from "../../redux/callcenter";
import DelayLead from "../DelayLead";
import LeadOverview from "../LeadOverview";
import {
  logLeadEngagement,
  purchaseLead,
  updateLeadByLead,
} from "../../services/Leads";
import moment from "moment-timezone";
import Button from "../Button";
import { useAppDispatch, useAppSelector } from "../../hooks";

const formFields = require("../../form_fields.json");

const EditLead = React.forwardRef((props, ref) => {
  const [dynamoData, setDynamoData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [actionIsLoaded, setActionIsLoaded] = useState(true);
  const [dataSet, setDataSet] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const socket = useSocket();
  const dispatch = useAppDispatch();

  const { callcenter, user } = useAppSelector((state) => state);
  const { selectedLeadId, leads, leadUpdates } = callcenter;
  const selectedLead = leads.filter((x) => x.lead_id === selectedLeadId)[0];

  const tabs = formFields.formSteps;
  const fields = formFields.formFields;

  const overviewForm = useRef();

  const [showRemoveModal, hideRemoveModal] = useModal(() => {
    return (
      <Modal
        onClose={() => {
          hideRemoveModal();
        }}
      >
        <RemoveLead
          onClose={hideRemoveModal}
          onCloseAll={() => {
            hideRemoveModal();
            props.closeModal();
            CloseLead(
              socket,
              leads.filter((x) => x.lead_id === selectedLeadId)[0]
            );
          }}
        />
      </Modal>
    );
  });

  const [showEditModal, hideEditModal] = useModal(() => {
    return (
      <Modal
        onClose={() => {
          hideEditModal();
        }}
      >
        <SaveLead
          closeAll={() => {
            hideEditModal();
          }}
        />
      </Modal>
    );
  });

  const [showDelayModal, hideDelayModal] = useModal(() => {
    return (
      <Modal
        onClose={() => {
          hideDelayModal();
        }}
      >
        <DelayLead
          closeModal={hideDelayModal}
          closeAll={() => {
            hideDelayModal();
            props.closeModal();
            CloseLead(
              socket,
              leads.filter((x) => x.lead_id === selectedLeadId)[0]
            );
          }}
        />
      </Modal>
    );
  });

  const onSaveUpdates = () => {
    showEditModal();
  };

  const onNoAnswer = () => {
    setActionIsLoaded(false);
    logLeadEngagement({
      type: "call_disposition",
      action: "no_answer",
      log: "Client did not answer",
      lead: selectedLeadId,
    }).then(() => {
      setActionIsLoaded(true);
      CloseLead(socket, leads.filter((x) => x.lead_id === selectedLeadId)[0]);
      props.closeModal();
    });
  };

  const onQualifyLead = () => {
    setActionIsLoaded(false);
    confirm(
      "Are you sure you want to qualify this lead? It will be removed from the call list!"
    ) &&
      updateLeadByLead(selectedLeadId, {
        qualified_at: moment().format("YYYY-MM-DD HH:mm"),
        qualified: true,
      })
        .then((res) => {
          logLeadEngagement({
            type: "call_disposition",
            lead: selectedLeadId,
            action: "qualified",
            log: "Lead was qualified",
          });
        })
        .then(() => {
          setActionIsLoaded(true);
          props.closeModal();
          CloseLead(
            socket,
            leads.filter((x) => x.lead_id === selectedLeadId)[0]
          );
        });
  };

  const onDelayLead = () => {
    showDelayModal();
  };

  const onRemoveLead = () => {
    showRemoveModal();
  };

  const onBuyLead = () => {
    purchaseLead({
      lead: selectedLeadId,
      callcenter: true,
    }).then((res) => {
      logLeadEngagement({
        type: "call_disposition",
        lead: selectedLeadId,
        action: "appointment_booked",
        log: "Lead has been purchased via the Callcenter",
      }).then(() => {
        window.location.reload();
      });
    });
  };

  useImperativeHandle(ref, () => ({
    closeSocketLead: () => {
      if (leads.filter((x) => x.lead_id === selectedLeadId)[0]) {
        CloseLead(socket, leads.filter((x) => x.lead_id === selectedLeadId)[0]);
      }
    },
  }));

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      valueRender: (text) => {
        const val = "" + text.value;
        return val.split(".")[val.split(".").length - 1].split("_").join(" ");
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      editor: {
        type: "input",
        component: InputEditor,
      },
    },
  ];
  const setAsQualifying = () => {
    if (selectedLeadId && leads.filter((x) => x.lead_id == selectedLeadId)[0]) {
      updateLeadByLead(selectedLeadId, {
        qualifying_at: moment.tz(moment(), "Europe/London").utc().format(),
      })
        .then((r) => {
          console.log(r);
        })
        .catch((reason) => {
          console.error(reason);
          alert(
            "There was an error while updating the lead to qualifying, please try again later."
          );
        });
    }
  };

  useEffect(() => {
    let qualifyingInterval;
    fetch("/callcenter/leads/" + selectedLeadId, { method: "GET" })
      .then((data) => data.json())
      .then((data) => {
        setDynamoData(data);
        setIsLoading(false);
        setDataSet(
          fields.map((path, index) => {
            return {
              key: path,
              name: path,
              value: resolve(path, data) || null,
            };
          })
        );
        if (leads.filter((x) => x.lead_id == selectedLeadId)[0]) {
          setAsQualifying();
          qualifyingInterval = setInterval(setAsQualifying, 90000);
          OpenLead(
            socket,
            leads.filter((x) => x.lead_id == selectedLeadId)[0],
            `${user?.user?.first_name} ${user?.user?.last_name}`
          );
        }
      })
      .catch((err) => {
        alert("This lead has just sold, refreshing!");
        window.location.reload();
      });
    return () => {
      clearInterval(qualifyingInterval);
    };
  }, []);

  if (isLoading) {
    return <LoadingSpinner text={"Loading client data"} />;
  }

  return (
    <div ref={ref}>
      <div className="user-info flex">
        <p className="h3 margin-none">
          Mortgage for{" "}
          {`${dynamoData?.RawCreateData?.person_1_title} ${dynamoData?.RawCreateData?.person_1_fname} ${dynamoData?.RawCreateData?.person_1_lname}`}
        </p>
        <button
          onClick={(e) => {
            e.preventDefault();
            onBuyLead();
          }}
          className="button smallest close background-green text-color-white"
        >
          Purchase this lead
        </button>
      </div>
      <div className="spacer"></div>
      <div className="call-to-actions full">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab className={classNames("value-viewer-content")}>Overview</Tab>
            {tabs.map((tabName) => {
              const name = tabName.replace("step_", "").split("_").join(" ");
              return (
                <Tab
                  key={tabName}
                  className={classNames("value-viewer-content")}
                >
                  {name === "RawCreateData" ? "Basic Information" : name}
                </Tab>
              );
            })}
          </TabList>
          <TabPanel key={"Overview"}>
            <LeadOverview ref={overviewForm} dynamoData={dynamoData} />
          </TabPanel>
          {tabs.map((tabName) => (
            <TabPanel key={tabName}>
              <ReactEditeTable
                dataSource={[
                  ...dataSet
                    .filter((x) => x.key.startsWith(tabName))
                    .map((entry) => {
                      if (leadUpdates.hasOwnProperty(entry.key)) {
                        return {
                          key: entry.key,
                          name: entry.name,
                          value: leadUpdates[entry.key],
                        };
                      } else {
                        return entry;
                      }
                    }),
                ]}
                columns={columns}
                couldDeleteRow={false}
                onChange={(newDataSource) => {
                  if (newDataSource?.preValue != newDataSource?.newValue) {
                    dispatch(
                      setLeadUpdates({
                        [dataSet.filter((x) => x.key.startsWith(tabName))[
                          newDataSource.rowIndex
                        ].name]: newDataSource?.newValue,
                      })
                    );
                  }
                }}
              />
            </TabPanel>
          ))}
        </Tabs>
      </div>
      <div className="spacer"></div>
      <div className="lead-actions">
        {Object.values(callcenter.leadUpdates).length != 0 ? (
          <div className={"button-list"}>
            <Button
              onClick={() => {
                onSaveUpdates();
              }}
              isLoaded={actionIsLoaded}
              title={"Save Changes"}
              colour={"background-green"}
              textColor={"text-color-white"}
            />
            <Button
              onClick={() => {
                setActionIsLoaded(false);
                dispatch(clearLeadUpdates());
                overviewForm?.current?.clearEdits();
                setActionIsLoaded(true);
              }}
              isLoaded={actionIsLoaded}
              title={"Cancel changes"}
              colour={"background-dark-grey"}
              textColor={"text-color-standard"}
            />
          </div>
        ) : (
          <div className={"button-list"}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                onQualifyLead();
              }}
              colour={"background-green"}
              isLoaded={actionIsLoaded}
              textColor={"text-color-white"}
              title={"Qualified"}
            />
            {!selectedLead?.callback_schedule_for && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onDelayLead();
                }}
                colour={"background-yellow"}
                isLoaded={actionIsLoaded}
                title={"Schedule callback"}
                textColor={"text-color-white"}
              />
            )}
            <Button
              onClick={(e) => {
                e.preventDefault();
                onRemoveLead();
              }}
              className="button smallest close background-red text-color-white"
              title={"Remove Lead"}
              isLoaded={actionIsLoaded}
              colour={"background-red"}
              textColor={"text-color-white"}
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                onNoAnswer();
              }}
              title={"No answer"}
              colour={"background-dark-grey"}
              isLoaded={actionIsLoaded}
              textColor={"text-color-standard"}
            />
          </div>
        )}
      </div>
    </div>
  );
});
export default EditLead;
