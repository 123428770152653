import React from "react";
import Field, { FieldLabel, FieldWrapper } from "../Field";
import CheckboxList from "../CheckboxList";
import Button from "../Button";
import { addAdvisorToCompany } from "../../services/Users";

const AddUserForm = () => {
  const [formValues, setFormValues] = React.useState<any>({});
  const [formErrors, setFormErrors] = React.useState<
    {
      name: string;
      message: string;
    }[]
  >([]);
  const onFieldChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = () => {
    setFormErrors([]);
    if (formValues.password1 !== formValues.password2) {
      setFormErrors([
        ...formErrors,
        {
          name: "password2",
          message: "Passwords do not match",
        },
      ]);
      return;
    }

    addAdvisorToCompany(formValues).then((res) => {
      window.location.reload();
    });
  };

  return (
    <div className="user-info">
      <form>
        <p className="h3 margin-half">Add a new user</p>
        <p>
          Fill out all the necessary information below to add a user to your
          team
        </p>
        <Field
          as={"input"}
          name={"first_name"}
          label={"First Name"}
          placeholder={"First Name"}
          required={true}
          onChange={onFieldChange}
          error={
            formErrors.find((error) => error.name === "first_name")?.message
          }
        />
        <Field
          as={"input"}
          name={"last_name"}
          label={"Last Name"}
          placeholder={"Last Name"}
          required={true}
          onChange={onFieldChange}
          error={
            formErrors.find((error) => error.name === "last_name")?.message
          }
        />
        <div className="spacer"></div>
        <Field
          as={"input"}
          type={"email"}
          name={"email"}
          label={"Email address"}
          placeholder={"Email address"}
          required={true}
          onChange={onFieldChange}
          error={formErrors.find((error) => error.name === "email")?.message}
        />
        <Field
          as={"input"}
          type={"password"}
          name={"password1"}
          label={"Password"}
          placeholder={"Password"}
          required={true}
          onChange={onFieldChange}
          error={
            formErrors.find((error) => error.name === "password1")?.message
          }
        />
        <Field
          as={"input"}
          type={"password"}
          name={"password2"}
          label={"Confirm password"}
          placeholder={"Confirm password"}
          required={true}
          onChange={onFieldChange}
          error={
            formErrors.find((error) => error.name === "password2")?.message
          }
        />
        <Field
          as={"input"}
          type={"tel"}
          name={"phonenumber"}
          label={"Telephone Number"}
          placeholder={"Telephone Number"}
          required={true}
          onChange={onFieldChange}
          error={
            formErrors.find((error) => error.name === "telephone")?.message
          }
        />
        <Field
          as={"input"}
          type={"text"}
          name={"individual_fca_number"}
          label={"Individual FCA number"}
          placeholder={"Individual FCA number"}
          required={true}
          onChange={onFieldChange}
          error={
            formErrors.find((error) => error.name === "individual_fca_number")
              ?.message
          }
        />
        <Field
          as={"input"}
          type={"text"}
          name={"location"}
          label={"Location"}
          placeholder={"Location"}
          required={true}
          onChange={onFieldChange}
          error={formErrors.find((error) => error.name === "location")?.message}
        />
        <FieldWrapper>
          <FieldLabel label={"Job type"} />
          <CheckboxList
            options={[
              {
                as: "radio",
                type: "radio",
                name: "job_type",
                label: "Independent mortgage advisor",
                value: "Independent mortgage advisor",
                inputOnly: true,
                className: "custom-checkbox",
                defaultValue: formValues?.job_type ?? "",
                onChange: onFieldChange,
                error: formErrors.find((error) => error.name === "job_type")
                  ?.message,
              },
              {
                as: "radio",
                type: "radio",
                name: "job_type",
                label: "Mortgage broker",
                value: "Mortgage broker",
                inputOnly: true,
                className: "custom-checkbox",
                defaultValue: formValues?.job_type ?? "",
                onChange: onFieldChange,
                error: formErrors.find((error) => error.name === "job_type")
                  ?.message,
              },
              {
                as: "radio",
                type: "radio",
                name: "job_type",
                label: "Independent financial advisor",
                value: "Independent financial advisor",
                inputOnly: true,
                className: "custom-checkbox",
                defaultValue: formValues?.job_type ?? "",
                onChange: onFieldChange,
                error: formErrors.find((error) => error.name === "job_type")
                  ?.message,
              },
            ]}
          />
        </FieldWrapper>
        <Button
          onClick={() => {
            onSubmit();
          }}
          title={"Submit"}
          colour={"background-green"}
          textColor={"text-color-white"}
        />
      </form>
    </div>
  );
};

export default AddUserForm;
