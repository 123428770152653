import PageTemplate from "../components/PageTemplate";
import React from "react";
import MarketplacePage from "../pages/Marketplace";
import CallcenterPage from "../pages/Callcenter";
import CallcenterKPIPage from "../pages/CallcenterKPIPage";
import { Page } from "../types/Page.type";
import ManageUsersPage from "../pages/ManageUsers";

const CallcenterPages = [
  {
    id: "callcenter",
    name: "Callcenter",
    url: "/portal/callcenter",
    class: "callcenter",
    hidden: true,
    permissions: ["Callcenter User", "Callcenter Manager"],
    props: {
      element: (
        <PageTemplate
          breadcrumbs={["Giving Mortgages", "Callcenter"]}
          title={"Callcenter Portal"}
        >
          <CallcenterPage pageId={"Callcenter"} />
        </PageTemplate>
      ),
    },
  },
  {
    id: "callcenter-pagination",
    name: "Callcenter",
    url: "/portal/callcenter/:currentPage/:pageSize",
    class: "callcenter",
    hidden: true,
    permissions: ["Callcenter User", "Callcenter Manager"],
    props: {
      element: (
        <PageTemplate
          breadcrumbs={["Giving Mortgages", "Callcenter"]}
          title={"Callcenter Portal"}
        >
          <CallcenterPage pageId={"Callcenter"} />
        </PageTemplate>
      ),
    },
  },
  {
    id: "callcenter-kpi",
    name: "Callcenter Statistics",
    url: "/portal/callcenter/statistics",
    class: "callcenter-statistics",
    permissions: ["Callcenter Manager"],
    hidden: true,
    props: {
      element: (
        <PageTemplate
          breadcrumbs={["Giving Mortgages", "Callcenter", "Statistics"]}
          title={"Callcenter Statistics"}
        >
          <CallcenterKPIPage />
        </PageTemplate>
      ),
    },
  },
];

const Pages = [
  {
    id: "dashboard",
    name: "Dashboard",
    url: "/dashboard",
    class: "dashboard",
  },
  {
    id: "marketplace",
    name: "Marketplace",
    url: "/marketplace",
    class: "marketplace",
    props: {
      element: (
        <PageTemplate
          breadcrumbs={["Dashboard", "Marketplace"]}
          title={"Marketplace"}
        >
          <MarketplacePage />
        </PageTemplate>
      ),
    },
  },
  {
    id: "marketplace-lead",
    name: "Marketplace",
    url: "/marketplace/:lead",
    class: "marketplace",
    hidden: true,
    props: {
      element: (
        <PageTemplate
          breadcrumbs={["Dashboard", "Marketplace"]}
          title={"Marketplace"}
        >
          <MarketplacePage />
        </PageTemplate>
      ),
    },
  },
  {
    id: "marketplace-notification",
    name: "Marketplace",
    url: "/marketplace/notification/:notification",
    class: "marketplace",
    hidden: true,
    props: {
      element: (
        <PageTemplate
          breadcrumbs={["Dashboard", "Marketplace"]}
          title={"Marketplace"}
        >
          <MarketplacePage />
        </PageTemplate>
      ),
    },
  },
  {
    id: "deal-pipeline",
    name: "Deal pipeline",
    url: "/pipeline",
    class: "deal-pipeline",
  },
  {
    id: "wallet",
    name: "Wallet",
    url: "/wallet",
    class: "wallet",
  },
  {
    id: "wallet-transaction",
    name: "Manage users",
    url: "/portal/manage_users",
    class: "manage-users",
    permissions: ["Advisor Company Manager"],
    props: {
      element: (
        <PageTemplate
          breadcrumbs={["Dashboard", "Manage User"]}
          title={"Manage Users"}
        >
          <ManageUsersPage />
        </PageTemplate>
      ),
    },
  },
  {
    id: "notifications",
    name: "Notifications",
    url: "/notifications",
    class: "notifications",
  },
  ...CallcenterPages,
] as Page[];

export default Pages;
