import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedLeadId: null,
  leads: [],
  callbackLeads: [],
  qualifiedLeads: [],
  soldLeads: [],
  removedLeads: [],
  leadUpdates: {},
  bestTimeToCall: [],
  notificationModal: {
    name: null,
    mortgage_value_min: undefined,
    mortgage_value_max: undefined,
    ltv_value_min: undefined,
    ltv_value_max: undefined,
    income_multi_value_min: undefined,
    income_multi_value_max: undefined,
    purpose: undefined,
    location: undefined,
    credit_score: undefined,
    frequency: null,
  },
};

const callcenter = createSlice({
  name: "call-center",
  initialState,
  reducers: {
    setSelectedLead(state, action) {
      state.selectedLeadId = action.payload;
    },
    setLeadData(state, action) {
      (async () => {
        state.leads = action?.payload;
      })();
    },
    setCallbackData(state, action) {
      (async () => {
        state.callbackLeads = action?.payload;
      })();
    },
    setQualifiedLeads(state, action) {
      (async () => {
        state.qualifiedLeads = action?.payload;
      })();
    },
    setRemovedLeads(state, action) {
      (async () => {
        state.removedLeads = action?.payload;
      })();
    },
    setSoldLeads(state, action) {
      (async () => {
        state.soldLeads = action?.payload;
      })();
    },
    setLeadUpdates(state, action) {
      state.leadUpdates = {
        ...state.leadUpdates,
        ...action.payload,
      };
    },
    setNotificationModal(state, action) {
      state.notificationModal = {
        ...state.notificationModal,
        ...action.payload,
      };
    },
    setClearNotificationModal(state, action) {
      state.notificationModal = {
        name: null,
        mortgage_value_min: undefined,
        mortgage_value_max: undefined,
        ltv_value_min: undefined,
        ltv_value_max: undefined,
        income_multi_value_min: undefined,
        income_multi_value_max: undefined,
        purpose: undefined,
        location: undefined,
        credit_score: undefined,
        frequency: null,
      };
    },
    clearLeadUpdates(state) {
      state.leadUpdates = {};
    },
    setBestTimeToCall(state, action) {
      // create an object from the array
      const obj = action.payload.results.reduce(
        (
          acc: { [x: string]: any },
          cur: { id: string | number; time: any }
        ) => {
          acc[cur.id] = cur.time;
          return acc;
        },
        {}
      );

      state.bestTimeToCall = obj;
    },
  },
});

export const {
  setLeadData,
  setQualifiedLeads,
  setSoldLeads,
  setSelectedLead,
  setLeadUpdates,
  clearLeadUpdates,
  setBestTimeToCall,
  setNotificationModal,
  setCallbackData,
  setRemovedLeads,
  setClearNotificationModal,
} = callcenter.actions;
export default callcenter.reducer;
