import React from "react";
import { useSelector } from "react-redux";
import { purchaseLead } from "../../services/Leads";

type Props = {
  onClose: () => void;
};

const BuyLead = ({ onClose }: Props) => {
  const { selectedLeadId, leads } = useSelector(
    (state: any) => state.marketplace
  );
  const selectedLead = leads.find(
    (lead: any) => lead.lead_id === selectedLeadId
  );
  return (
    <>
      <div className="user-info">
        <p className="h3 margin-none">Confirm purchase</p>
        <div className="price-list">
          {selectedLead?.discounted_price && (
            <p
              className="h5 text-weight-400 purchase_modal_lead_cost_wrapper_discounted"
              id="purchase_modal_lead_cost_wrapper"
            >
              <i className="fa-solid fa-coins text-color-yellow"></i>{" "}
              <span id="purchase_modal_lead_cost">{selectedLead.price}</span>{" "}
              credits
            </p>
          )}
          <p
            className="h5 text-weight-400"
            id="purchase_modal_discounted_price"
          >
            <i className="fa-solid fa-coins text-color-yellow"></i>{" "}
            <span id="purchase_modal_lead_cost_discounted">
              {selectedLead?.discounted_price === ""
                ? selectedLead.price
                : selectedLead?.discounted_price}
            </span>{" "}
            credits
          </p>
        </div>
        <div className="details-list">
          <ul>
            <li>
              <p className="h6 margin-none">Mortgage value</p>
              <span id="purchase_modal_mortgage_value">
                {selectedLead.mortgage_value}
              </span>
            </li>
            <li>
              <p className="h6 margin-none">Mortgage type</p>
              <span id="purchase_modal_mortgage_type">
                {selectedLead.mortgage_type}
              </span>
            </li>
            <li>
              <p className="h6 margin-none">Purpose</p>
              <span id="purchase_modal_purpose">
                {selectedLead.mortgage_purpose}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="spacer"></div>
      <div className="call-to-actions full">
        <form>
          <ul>
            <li className="lead_purchase_confirm">
              <button
                id="purchase_modal_buy_button"
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  purchaseLead({
                    lead: selectedLead.lead_id,
                  }).then(() => window.location.reload());
                }}
              >
                Confirm purchase
              </button>
            </li>
            <li className="lead_purchase_cancel close">
              <span
                className="button smallest background-grey"
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
              >
                Cancel
              </span>
            </li>
          </ul>
        </form>
      </div>
    </>
  );
};

export default BuyLead;
