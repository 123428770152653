import React from "react";
import "./styles.scss";

type Props = {
  text?: string;
};

const LoadingSpinner = ({ text }: Props) => {
  return (
    <div className="loading-spinner-wrap">
      <span className={"loading-spinner"}></span>
      <span className="text-size-smallest text">{text ? text : "Loading"}</span>
    </div>
  );
};

export default LoadingSpinner;
