import axios from "axios";

// export const getBestTimesToCall = () => {
//   return fetch("/api/lead/call/time/", {
//     method: "GET",
//     headers: requestHeaders,
//   }).then((r) => r.json());
// };

export const getBestTimesToCall = () => {
  return axios.get("/api/lead/call/time/").then((r) => r.data);
};

export type BestTimeToContact = {
  lead: string;
  call_time: number[];
};

export const setBestTimesToCall = (
  data: BestTimeToContact,
  useUpdate: Boolean,
  id?: string
) => {
  let url = "/api/lead/attribute/";
  if (useUpdate) {
    url = url + id + "/";
  }

  return axios[useUpdate ? "put" : "post"](url, data).then((r) => r.data);
};

export const getBestTimesToCallByLead = (lead: string) => {
  return axios.get(`/api/lead/attribute/?lead__id=${lead}`).then((r) => r.data);
};
