import React from "react";

type PipelineStatsProps = {
  advisor_id: number;
  leads_in_no_contact: number;
  leads_in_contact_made: number;
  leads_in_scheduled: number;
  leads_in_converted: number;
  leads_in_no_sale: number;
  leads_in_deal_complete: number;
};

const PipelineStats = ({
  advisor_id,
  leads_in_no_contact,
  leads_in_converted,
  leads_in_contact_made,
  leads_in_no_sale,
  leads_in_scheduled,
  leads_in_deal_complete,
}: PipelineStatsProps) => {
  return (
    <div className="d-flex align-items-center justify-content-start">
      <span className="status inline background-grey" title="No contact">
        {leads_in_no_contact}
      </span>
      <span className="status inline background-yellow" title="Contact made">
        {leads_in_contact_made}
      </span>
      <span
        className="status inline background-blue"
        title="Scheduled call / meeting"
      >
        {leads_in_scheduled}
      </span>
      <span
        className="status inline background-dark-blue"
        title="Converted to client"
      >
        {leads_in_converted}
      </span>
      <span className="status inline background-red" title="No sale">
        {leads_in_no_sale}
      </span>
      <span className="status inline background-green" title="Deal complete">
        {leads_in_deal_complete}
      </span>

      <span className="um-view-more">
        <a href={`/pipeline/user/${advisor_id}/`}>View All</a>
      </span>
    </div>
  );
};

export default PipelineStats;
