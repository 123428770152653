import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  messages: [],
  cache: [],
};

const websocket = createSlice({
  name: "call-center",
  initialState,
  reducers: {
    setMessages(state, action) {
      console.log(action.payload);
      switch (action.payload.ACTION) {
        case "UPDATE_LEAD":
          state.messages = [...state.messages, action.payload];
          break;
        case "UPDATE_CACHE":
          state.cache = action.payload.RESPONSE;
          break;
        default:
          state.messages = [...state.messages, action.payload];
          break;
      }
    },
    setCache(state, action) {
      state.cache = action.payload;
    },
  },
});

export const { setMessages, setCache } = websocket.actions;
export default websocket.reducer;
