import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import Pages from "./constants/pages";
import { Provider } from "react-redux";
import { store } from "./redux/configureStore";
import ErrorBoundary from "./components/ErrorBoundary";
import { ModalProvider } from "react-modal-hook";
import { datadogRum } from "@datadog/browser-rum";
import DataProvider from "./components/DataProvider/DataProvider";
import { checkPermission } from "./helpers";
import { useAppSelector } from "./hooks";
import "./axios";

const App = () => {
  const { user } = useAppSelector((state) => state?.user);
  useEffect(() => {
    if (process.env.DL_ENV === "prod") {
      datadogRum.init({
        applicationId: process.env.DL_APP_ID || "",
        clientToken: process.env.DL_CLIENT_TOKEN || "",
        site: "datadoghq.eu",
        service: "portal-react-logging",
        version: "1.0.0",
        env: process.env.DL_ENV,
        sampleRate: 100,
        premiumSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
      });
    }

    if (window.location.search) {
      const query = window.location.search;
      const path = window.location.pathname;
      if (query.includes("?lead=") && path === "/marketplace") {
        window.location.replace(
          "/marketplace/" + window.location.search.split("=")[1]
        );
      } else if (query.includes("?notification=") && path === "/marketplace") {
        window.location.replace(
          "/marketplace/notification/" + window.location.search.split("=")[1]
        );
      }
    }
  }, []);
  return (
    <ErrorBoundary>
      <ModalProvider>
        <DataProvider>
          <BrowserRouter>
            <Navigation />
            <Routes>
              {Pages.filter((page) =>
                checkPermission(user?.groups ?? [], page?.permissions ?? '')
              ).map((page, index) => {
                return <Route path={page.url} {...page?.props} key={index} />;
              })}
            </Routes>
          </BrowserRouter>
        </DataProvider>
      </ModalProvider>
    </ErrorBoundary>
  );
};

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
