import React, { ReactNode } from "react";

type Props = {
  title: string | ReactNode;
  body?: ReactNode;
  children: JSX.Element;
};

const Module = ({ title, body, children }: Props) => {
  return (
    <div className="flex-container module-table">
      <div className="column">
        <div className="module border-none">
          <div className="module-heading">
            {typeof title === "string" ? <p className="h6">{title}</p> : title}
          </div>
          <div className="module-description padding-bottom-half">
            {body && body}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Module;
