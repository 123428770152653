export const getTableData = (
  rawData: any,
  cacheData: any,
  websocketData: any
) => {
  const leadUpdates = websocketData
    .filter((message: any) => message.ACTION == "UPDATE_LEAD")
    .map((message: any) => message.RESPONSE);
  const leadCache = cacheData
    .filter((message: any) => message.ACTION == "UPDATE_LEAD")
    .map((message: any) => message.RESPONSE);

  let data: any[] = [];

  rawData.forEach((lead: any) => {
    let tempLead = lead;
    const updates = leadUpdates.filter(
      (x: any) => x?.lead_id === lead?.lead_id
    );
    const cacheUpdates = leadCache.filter(
      (x: any) => x?.lead_id === lead?.lead_id
    );

    if (cacheUpdates.length > 0) {
      cacheUpdates.forEach((update: any) => {
        tempLead = {
          ...update,
        };
      });
    }

    if (updates.length > 0) {
      updates.forEach((update: any) => {
        tempLead = {
          ...update,
        };
      });
    }

    data.push(tempLead);
  });
  return data;
};
