import React, { useEffect, useState } from "react";
import classNames from "classnames";

import "./styles.scss";
import MarketplaceFilters from "../../constants/marketplaceFilters";
import { useModal } from "react-modal-hook";
import Modal from "../Modal";
import { INotification } from "../../services/Notifications";
import { setNotificationModal } from "../../redux/callcenter";
import CreateNotification from "../CreateNotification";
import { useAppDispatch, useAppSelector } from "../../hooks";

type FilterProps = {
  onChange: (e: any) => void;
  notification?: Notification;
};

const LeadFilters = ({ onChange, notification }: FilterProps) => {
  const [selectedValues, setSelectedValues] = useState<INotification>({
    name: null,
    credit_score: undefined,
    frequency: null,
    location: undefined,
    purpose: undefined,
  });

  const { notificationModal } = useAppSelector(
    (state: any) => state.callcenter
  );
  const dispatch = useAppDispatch();
  const [showFilters, setShowFilters] = useState(true);

  const [openNotificationModal, hideNotificationModal] = useModal(() => (
    <Modal onClose={hideNotificationModal}>
      <CreateNotification closeModal={hideNotificationModal} />
    </Modal>
  ));

  useEffect(() => {
    // if screen is 768px or less, hide filters
    if (window.innerWidth <= 768) {
      setShowFilters(false);
    }
  }, []);

  return (
    <>
      <span
        className="button background-blue smallest show_filters"
        onClick={(e) => {
          e.preventDefault();
          setShowFilters(!showFilters);
        }}
      >
        <i className="fa-solid fa-bars-filter"></i>{" "}
        <span>{showFilters ? "Hide" : "Show"}</span> filters
      </span>
      <form
        className={classNames("marketplace_filters", {
          filters_hidden: !showFilters,
        })}
      >
        <div>
          {MarketplaceFilters.map((type: any, index: number) => {
            let selectedValue = "";

            if (notification && type?.selectedValue) {
              const defaultValue = type.selectedValue(
                notification,
                type.options
              );

              selectedValue = defaultValue;
            } else {
              selectedValue = type.selectedValue(
                notificationModal,
                type.options
              );
            }

            if (selectedValues[type.id as keyof object]) {
              selectedValue = selectedValues[type.id as keyof object];
            }

            return (
              <div className="filter" key={index}>
                <select
                  name={type.id}
                  value={selectedValue}
                  onChange={(e) => {
                    dispatch(
                      setNotificationModal({
                        ...notificationModal,
                        ...type.options[e.target.value].value,
                      })
                    );
                    onChange(type.options[e.target.value].value);
                  }}
                >
                  <option disabled={true} value={"N/A"}>
                    {type.name}
                  </option>
                  {Object.keys(type.options).map((option, index) => {
                    return (
                      <option value={option} key={index}>
                        {type.options[option as keyof object].label}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          })}
          <div className="filter">
            <span
              className={
                "button smallest modal_trigger notification_create_modal"
              }
              onClick={(e) => {
                e.preventDefault();
                openNotificationModal();
              }}
            >
              Create notification
            </span>
          </div>
        </div>
      </form>
    </>
  );
};

export default LeadFilters;
