import axios from "axios";

export type INotification = {
  name: string | null;
  mortgage_value_min?: number;
  mortgage_value_max?: number;
  ltv_value_min?: number;
  ltv_value_max?: number;
  income_multi_value_min?: number;
  income_multi_value_max?: number;
  purpose?: "BTL" | "PUR" | "RM" | "FTB" | "ER" | null;
  location?: string | null;
  credit_score?: "good" | "fair" | "bad" | "excellent" | null;
  frequency: "instant" | "1h" | "morning" | "afternoon" | "evening" | null;
};

export const NotificationMap = {
  mortgage_value_max: "Max Mortgage Value",
  mortgage_value_min: "Min Mortgage Value",
  ltv_value_max: "Max LTV Value",
  ltv_value_min: "Min LTV Value",
  income_multi_value_max: "Max Income Multiplier",
  income_multi_value_min: "Min Income Multiplier",
  purpose: "Purpose",
  location: "Location",
  credit_score: "Credit Score",
};

export const getNotificationById = (id: string) => {
  return axios.get(`/api/lead/notification/${id}/`).then((r) => r.data);
};

export const createNotification = (notification: INotification) => {
  return axios
    .post(`/api/lead/notification/`, notification)
    .then((r) => r.data);
};

export const updateNotification = (id: string, notification: INotification) => {
  return axios
    .put(`/api/lead/notification/${id}/`, notification)
    .then((r) => r.data);
};
